// @mui
import MUIDataTable from "mui-datatables";
import {
    Card,
    Container,
    Grid
} from '@mui/material';
import { useCallback, useState, useEffect, useMemo } from 'react';
import axios from '../../../utils/axios';


// ----------------------------------------------------------------------

export default function Next7DaysSchedule() {
  useEffect(() => { GetToday() }, [])
const [data, setData] = useState()
const GetToday = async () => {
  const response = await axios.get(`api/superadmin/weekjobs`);
  const { weekjobs } = response.data;
  setData(weekjobs);
  console.log('weekjobs==>', weekjobs)
}

const columns = [
  {
    name: "assetid",
    label: "Asset ",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "customer",
    label: "Customer Company",
    options: {
      customBodyRender: (value, row) => {
        return value?.company?.companyname;
      },
    },
  },
  {
    name: "contractorcompanyname",
    label: "Contractor Company",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "technicianname",
    label: "Technician",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "jobscheduleddate",
    label: "Job Date",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "jobtype",
    label: "Job Type",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "jobstatus",
    label: "Job Status",
    options: {
      filter: true,
      sort: true,
    }
  },
];

const options = {
  filterType: "dropdown",
};
  return (
    <Card>
    <MUIDataTable
      title={"Next 7 Days Jobs"}
      data={data}
      columns={columns}
      options={{
        selectableRows: false // <===== will turn off checkboxes in rows
      }}
    />
  </Card>
  );
}

// ----------------------------------------------------------------------


