import { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
// @mui
import MUIDataTable from "mui-datatables";
import {
  Card,
  Container,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from '../../../utils/axios';

// utils
import { fDateTime } from '../../../utils/formatTime';
// _mock_

// ----------------------------------------------------------------------

export default function TomorrowSchedule() {

  useEffect(() => { GetToday() }, [])
  const [data, setData] = useState()
  const GetToday = async () => {
    const response = await axios.get(`/api/superadmin/tomorrowjobs`);
    console.log("all data ahmed baloch ===>".response)
    const { tomorrowjobs } = response.data;
    setData(tomorrowjobs);
    console.log('todayjobs==>', tomorrowjobs)
  }

    const columns = [
      {
        name: "assetid",
        label: "Asset ",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "customer",
        label: "Customer Company",
        options: {
          customBodyRender: (value, row) => {
            return value?.company?.companyname;
          },
        },
      },
      {
        name: "contractorcompanyname",
        label: "Contractor Company",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "technicianname",
        label: "Technician Name",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "jobscheduleddate",
        label: "Job Date",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "jobtype",
        label: "Job Type",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "jobstatus",
        label: "Job Status",
        options: {
          filter: true,
          sort: true,
        }
      },
      
    ];
  
    const options = {
      filterType: "dropdown",
    };
  return (
    <Card>
      <MUIDataTable
        title={"Tomorrow Jobs"}
        data={data}
        columns={columns}
        options={{
          selectableRows: false // <===== will turn off checkboxes in rows
        }}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------


