// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Card, FormControl, Box, OutlinedInput } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
// sections
import Select from '@mui/material/Select';
import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { useSelector } from '../../redux/store';
import axios from '../../utils/axios';
// components
import Page from '../../components/Page';
import useResponsive from '../../hooks/useResponsive';
import useSettings from '../../hooks/useSettings';
// import { CalendarStyle, CalendarToolbar } from '../@Customer/calendar';
import { AppWidgetSummary } from '../@dashboard/general/app';
import TodaySchedule from '../@dashboard/schedule/TodaySchedule';
import Next7DaySchedule from '../@dashboard/schedule/Next7DaysSchedule'
import TomorrowSchedule from '../@dashboard/schedule/TomorrowSchedule';
import NextMonthSchedule from '../@dashboard/schedule/TomorrowSchedule';

// ----------------------------------------------------------------------

export default function CustomerAppDashBoard() {
  const {customeId} = useParams();
  console.log(customeId,"customerid")
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const isDesktop = useResponsive('up', 'sm');
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek');
  const { events } = useSelector((state) => state.calendar);
  const [show, setShow] = useState("ts");

  useEffect(() => { AllLocation() }, [])
  const [locationdata, setLocationData] = useState()
  const AllLocation = async () => {
    const response = await axios.get(`api/superadmin/customer/locationcount/${customeId}`);
    // const response = await axios.get(`api/customer/locationcount`);
    const { locationscount } = response.data;
    setLocationData(locationscount);
  }

  useEffect(() => { AllAsset() }, [])
  const [assetdata, setAssetData] = useState()
  const AllAsset = async () => {
    const response = await axios.get(`api/superadmin/customer/assetcount/${customeId}`);
    const { allassetscount } = response.data;
    setAssetData(allassetscount);
  }

  useEffect(() => { AllContractor() }, [])
  const [contractordata, setContractorData] = useState()
  const AllContractor = async () => {
    const response = await axios.get(`api/superadmin/customer/contractors/${customeId}`);
    const { contractorcount } = response.data;
    setContractorData(contractorcount);
  }

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeSelectDrop = (event) => {

    const getuser = event.target.value;
    console.log(" this onchange value == >", getuser)
    setShow(getuser)

  }

  return (
    <Page title="Customer: Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={3}>
            <Link to={`/customer/panel/ourcustomer/companylocinfo/${customeId}`}>
              <AppWidgetSummary
                title="My Locations"
                total={locationdata}
              />
            </Link>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* <Link to='/customer/panel/myassets'> */}
              <AppWidgetSummary
                title="My Asset"
                total={assetdata}
              />
            {/* </Link> */}
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <Link to='/customer/panel/mytask'> */}
              <AppWidgetSummary
                title="My Task"
                total={contractordata}
              />
            {/* </Link> */}
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <Link to='/customer/panel/contractor'> */}
              <AppWidgetSummary
                title="My Contractor"
                total={contractordata}
              />
            {/* </Link> */}
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 300}}>
                <Select
                  native
                  // value={age}
                  onChange={handleChangeSelectDrop}
                  input={<OutlinedInput label="Technician=" name="technician" id="demo-dialog-native" />}
                >
                  <option value='select' >Select Jobs</option>
                  <option value='ts' selected>Today Jobs</option>
                  <option value='toms' >Tomorrow Jobs</option>
                  <option value='n7s' >Next 7 Days Jobs</option>
                  <option value='nm' >After 7 Days Jobs</option>

                </Select>

              </FormControl>

            </Box>

          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {show === 'ts' && <TodaySchedule />}
            {show === 'n7s' && <Next7DaySchedule />}
            {show === 'toms' && <TomorrowSchedule />}
            {show === 'nm' && <NextMonthSchedule />}
          </Grid>
          <Grid item xs={12} md={12} lg={12} />
        </Grid>
      </Container>

      {/* <Container spacing={2} maxWidth={themeStretch ? false : 'xl'}>
          <Card>
            <CalendarStyle>
              <CalendarToolbar
                date={date}
                view={view}
                onNextDate={handleClickDateNext}
                onPrevDate={handleClickDatePrev}
                onToday={handleClickToday}
                onChangeView={handleChangeView}
              />
              <FullCalendar
                events={events}
                ref={calendarRef}
                headerToolbar={false}
                height={isDesktop ? 720 : 'auto'}
                plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
              />

            </CalendarStyle>
          </Card>
      </Container> */}
    </Page>
  );
}
