import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AdminLayout from '../layouts/admin';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_ADMIN_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import CustomerApp from 'src/pages/customer/Dashboard';
import CustomerAppDashBoard from 'src/sections/@Customer/CutomerDashboard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard/dashboard')} />}>
        <Component {...props} />
      </Suspense>
    </>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Navigate to='/admin/login' replace />
      ),
    },
    {
      path: 'admin',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <AdminLogin />
            </GuestGuard>
          ),
        },
        // forget Password
        {
          path: 'forgetpassword',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        // edit password on click on email link route
        {
          path: 'changepassword/:id',
          element: (
            <GuestGuard>
              <EditPassword />
            </GuestGuard>
          ),
        },
        // Admin Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <AdminLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />, index: true },
            { path: 'subadmin', element: <SubAdmin /> },
            { path: 'addsubadmin', element: <AddSubAdmin /> },
            { path: 'editsubadmin/:id', element: <AddSubAdmin /> },
            { path: 'dashboard', element: <AdminDashboard /> },
            { path: 'Cutomerdashboard/:customeId', element: <CustomerAppDashBoard/> },
            { path: 'task', element: <AdminTask /> },
            { path: 'company', element: <AdminCompany /> },
            { path: 'contractor', element: <Contractor /> },
            { path: 'contact', element: <AdminContact /> },
            { path: 'location', element: <AdminLocation /> },
            { path: 'companyaccess', element: <AdminCredential /> },
            { path: 'locationaccess', element: <LocationAccess /> },
            { path: 'assets', element: <AdminAssets /> },
            { path: 'customerapproval', element: <AdminCustomerApproval /> },
            { path: 'contractorapproval', element: <AdminContractorApproval /> },
            { path: 'job', element: <AdminJobTable /> },
            // { path: 'jobentryform/:jobid', element: <AdminJobEntryForm /> },
            { path: 'Job/add', element: <AdminJobEntryFormUpdate /> },
            { path: 'Job/update/:id', element: <AdminJobEntryFormUpdate /> },
            { path: 'myresource', element: <AdminMyResource /> },
            { path: 'onboarding', element: <AdminOnBoarding /> },
            { path: 'salesleads', element: <AdminSalesLeads /> },
            { path: 'reviewdataupdate', element: <AdminReviewDataUpdate /> },
            { path: 'reviewuploads', element: <AdminReviewUploads /> },
            { path: 'viewuploadfile/:id', element: <AdminViewUploadFile /> },
            { path: 'schedulesservices', element: <AdminSchedulesServices /> },
            { path: 'onboardinglist/:id', element: <OnboardingList /> },
            { path: 'adminmytask', element: <AdminMyTask /> },
            { path: 'adminmytaskcompleted', element: <AdminMyTaskCompleted /> },
            { path: 'elevatorTask', element: <ElevatorTask /> },
            { path: 'elevatorTaskCompleted', element: <ElevatorTaskCompleted/> },
            { path: 'fireDepartmentTask', element: <FireDepartmentTask /> },
            { path: 'fireDepartmentTaskCompleted', element: <FireDepartmentCompletedTask /> },
            { path: 'fireAlarmTask', element: <FireAlarmTask /> },
            { path: 'fireAlarmcompletedTask', element: <FireAlarmCompletedTasks /> },
            { path: 'wetSprinklerTask', element: <WetSprinklerTask /> },
            { path: 'wetSprinklercomppletedTask', element: <WetSprinklerCompletedTask /> },
            { path: 'drysprinklertask', element: <DrySprinklerTask /> },
            { path: 'drysprinklercompletedtask', element: <DrySprinklerCompletedTask /> },
            { path: 'preActionTask', element: <PreActionTask /> },
            { path: 'preActioncompletedTask', element: <PreActionCompletedTask /> },
            { path: 'delugeTask', element: <DelugeTask /> },
            { path: 'delugeCompletedTask', element: <DelugeCompletedTask /> },
            { path: 'standpipeTask', element: <StandpipeTask /> },
            { path: 'standpipeCompletedTask', element: <StandpipeCompletedTask /> },
            { path: 'firePumpTask', element: <FirePumpTask /> },
            { path: 'firePumpCompletedTask', element: <FirePumpCompletedTask /> },
            { path: 'kitchenHoodTask', element: <KitchenHoodTask /> },
            { path: 'kitchenHoodCompletedTask', element: <KitchenHoodCompletedTask /> },
            { path: 'dryChemicalTask', element: <DryChemicalTask /> },
            { path: 'dryChemicalCompletedTask', element: <DryChemicalCompletedTask /> },
            { path: 'CleanAgentTask', element: <CleanAgentTask /> },
            { path: 'CleanAgentCompletedTask', element: <CleanAgentCompletedTask /> },
            { path: 'fireEscapeTask', element: <FireEscapeTask /> },
            { path: 'fireEscapeCompletedTask', element: <FireEscapeCompleteTask /> },
            { path: 'backFlowTask', element: <BackFlowTask /> },
            { path: 'backFlowCompletedTask', element: <BackFlowCompletedTask /> },
            { path: 'otherLocationTask', element: <OtherLocationTask /> },
            { path: 'otherLocationCompletedTask', element: <OtherLocationCompletedTask /> },
            { path: 'addcontractor' , element: <AddContractor />},
            { path: ':contractorid/editcontractor' , element: <AddContractor />},
            { path: 'addcustomer' , element: <AddCustomer />},
            { path: 'updatecustomer/:id' , element: <AddCustomer />},
            { path: ':customerid/editcustomercompanylocinfo' , element: <AddCustomer />},
            { path: 'contractorcontactformAdd/:contractorcontactid' , element: <ContractorContactFormAdd />},
            { path: 'contractorcontactform/:contractorcontactid' , element: <ContractorContactForm />},
            { path: 'contractorcontactinfo/:contractorcontactid' , element: <ContractorContactInfo />},
            { path: 'contractorcompanyinfo/:contractorid' , element: <ContractorCompanyInfo />},
            { path: 'service/add' , element: <AdminService />},
            { path: 'service/update' , element: <AdminServiceUpdate />},

            {
              path: 'user',
              children: [
                { element: <Navigate to="/admin/user/profile" replace />, index: true },
                { path: 'profile', element: <UserProfile /> },
                { path: 'cards', element: <UserCards /> },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserCreate /> },
                { path: ':name/edit', element: <UserCreate /> },
                { path: 'account', element: <UserAccount /> },
              ],
            },
          ],
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
      ],
    },

    // Customer Auth
    {
      path: 'customer',
      children: [
        // Customer Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <AdminLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: 'ourcustomer',
              children: [
                { element: <Navigate to="/customer/ourcustomer/companyinfo" replace />, index: true },
                { path: 'companylocinfo/:customeId', element: <CompLocTable /> },
                { path: 'companyinfo/:locationId/:customeId', element: <CustomerCompanyInfo /> },
                { path: 'usercontactinfo/:customeId', element: <UserContactInfo /> },
                { path: 'locationinfo/:customeId', element: <LocationInfo /> },
                // new pages
                { path: 'cuslocationcontact/:locationId', element: <CusLocationContact /> },
                { path: 'cuslocationcontactform/:locationId', element: <CusLocationContactForm /> },
                { path: 'cuslocationcontactform/update/:contactId/:locationId', element: <CusLocationContactForm /> },


                
                { path: 'elevatorinfo', element: <CustomerElevator /> },
                { path: 'fireinfo', element: <CustomerFireDep /> },
                { path: 'keylogsinfo', element: <CustomerKeyLogs /> },
                { path: 'firealarmasset/:customeId', element: <CustomerFireAlarmAsset /> },
                { path: 'wetsprinkler/:customeId', element: <CustomerWetsprinkler /> },
                { path: 'drysprinkler/:customeId', element: <DrySprinkler /> },
                { path: 'preactionsprinkler/:customeId', element: <Preaction /> },
                { path: 'delugesprinkler/:customeId', element: <Deluge /> },
                { path: 'standpipe/:customeId', element: <Standpipe /> },
                { path: 'firepump/:customeId', element: <Firepumpsprinkler /> },
                { path: 'FireHydrant/:customeId', element: <FireHydrant /> },
                { path: 'kitchenhood/:customeId', element: <KitchenHoodSprinkler /> },
                { path: 'drychemical/:customeId', element: <DryChemical /> },
                { path: 'cleanagent/:customeId', element: <CleanAgent /> },
                { path: 'fireescape/:customeId', element: <FireEscape /> },
                { path: 'fireextinguisher/:customeId', element: <FireExtinguisher /> },
                { path: 'backflow/:customeId', element: <Backflow /> },
                { path: 'othersprinkler/:customeId', element: <OtherSprinkler /> },
                
                { path: 'companylocationquantity', element: <CompanyLocationQuantity /> },
                { path: 'deficiencyentryform', element: <DeficiencyEntryForm /> },
                { path: 'Jobentryform', element: <JobEntryForm /> },
                { path: 'locationassetinventory', element: <LocationAssetInventory /> },
                { path: 'serviceentryform/:serviceId', element: <ServiceEntryForm /> },
              ]
            },
            { path: 'serviceentry', element: <ServiceEntry /> },
            { path: 'location', element: <CustomerLocation /> },
            {
              path: 'ourtask',
              children: [
                { element: <Navigate to="/customer/ourtask/saleslead" replace />, index: true },
                { path: 'saleslead', element: <CustomerSalesleads /> },
                { path: 'onboarding', element: <CustomerOnboardings /> },
                { path: 'reviewdataupdate', element: <CustomerReviewdataupdates /> },
                { path: 'reviewupload', element: <CustomerReviewuploads /> },
                { path: 'scheduleservice', element: <CustomerScheduleservices /> },

              ],
            },
            {
              path: 'user',
              children: [
                { element: <Navigate to="/admindashboard/user/profile" replace />, index: true },
                { path: 'profile', element: <UserProfile /> },
                { path: 'cards', element: <UserCards /> },
                { path: 'list', element: <UserList /> },
                { path: 'new', element: <UserCreate /> },
                { path: ':name/edit', element: <UserCreate /> },
                { path: 'account', element: <UserAccount /> },
              ],
            },
            {
              path: 'deficiency', element: <CuDeficiencyEntryForm />
            },
          ],
        },
      ],
    },

    // Contractor Auth
    // {
    //   path: 'contractor',
    //   children: [
    //     {
    //       path: 'login',
    //       element: (
    //         <GuestGuard>
    //           <ContractorLogin />
    //         </GuestGuard>
    //       ),
    //     },
    //     {
    //       path: 'register',
    //       element: (
    //         <GuestGuard>
    //           <ConRegister />
    //         </GuestGuard>
    //       ),
    //     },
    //     { path: 'reset-password', element: <ConResetPassword /> },
    //     { path: 'new-password', element: <ConNewPassword /> },
    //     { path: 'verify', element: <ConVerifyCode /> },
    //     // Contractor Routes
    //     {
    //       path: 'panel',
    //       element: (
    //         <AuthGuard>
    //           <AdminLayout />
    //         </AuthGuard>
    //       ),
    //       children: [
    //         { element: <Navigate to={PATH_AFTER_CONTRACTOR_LOGIN} replace />, index: true },
    //         { path: 'dashboard', element: <ContractorDashboard /> },
    //         {
    //           path: 'ourcontractor',
    //           children: [
    //             { element: <Navigate to="/contractor/ourcontractor/companyinfo" replace />, index: true },
    //             { path: 'companyinfo', element: <ContractorCompanyInfo /> },
    //             // { path: 'usercontactinfo', element: <ContractorUserContactInfo /> },
    //             // { path: 'companyinfo', element: <ContractorCompanyInfo /> },
    //           ],
    //         },
    //         { path: 'location', element: <ContractorLocation /> },
    //         // { path: 'company', element: <ConCompany /> },

    //         {
    //           path: 'ourcompany',
    //           children: [
    //             { element: <Navigate to="/contractor/ourcompany/companyinfo" replace />, index: true },
    //             { path: 'companyinfo', element: <CompanyInfo /> },
    //             // { path: 'salelead', element: <Salelead /> },
    //             // { path: 'onboarding', element: <Onboarding /> },
    //             // { path: 'scheduleservice', element: <Scheduleservice /> },
    //             // { path: 'reviewuploads', element: <Reviewuploads /> },
    //             // { path: 'account', element: <UserAccount /> },
    //           ],
    //         },

    //         {
    //           path: 'ourtask',
    //           children: [
    //             { element: <Navigate to="/contractor/ourtask/reviewdataupdates" replace />, index: true },
    //             { path: 'reviewdataupdates', element: <Reviewdataupdates /> },
    //             { path: 'salelead', element: <Salelead /> },
    //             { path: 'onboarding', element: <Onboarding /> },
    //             { path: 'scheduleservice', element: <Scheduleservice /> },
    //             { path: 'reviewuploads', element: <Reviewuploads /> },
    //             { path: 'account', element: <UserAccount /> },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },

    // Technician Auth
    // {
    //   path: 'technician',
    //   children: [
     
    //     // Technician Routes
    //     {
    //       path: 'panel',
    //       element: (
    //         <AuthGuard>
    //           <AdminLayout />
    //         </AuthGuard>
    //       ),
    //       children: [
    //         { element: <Navigate to={PATH_AFTER_TECHNICIAN_LOGIN} replace />, index: true },
    //         { path: 'dashboard', element: <TecDashboard /> },
    //         { path: 'task', element: <TecTask /> },
    //         { path: 'technicianinfo', element: <TecInfo /> },
    //         { path: 'jobs', element: <TecJob /> },
    //         { path: 'jobscomingsoon', element: <TecJobcoming /> },
    //         {
    //           path: 'user',
    //           children: [
    //             { element: <Navigate to="/admindashboard/user/profile" replace />, index: true },
    //             { path: 'profile', element: <UserProfile /> },
    //             { path: 'cards', element: <UserCards /> },
    //             { path: 'list', element: <UserList /> },
    //             { path: 'new', element: <UserCreate /> },
    //             { path: ':name/edit', element: <UserCreate /> },
    //             { path: 'account', element: <UserAccount /> },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <AdminLogin />,
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

//  Authentication
const AdminLogin = Loadable(lazy(() => import('../pages/auth/AdminLogin')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const EditPassword = Loadable(lazy(() => import('../pages/auth/EditPassword')));
const SuperAdminLogin = Loadable(lazy(() => import('../pages/auth/SuperAdminLogin')));
// Admin
const SubAdmin = Loadable(lazy(() => import('../pages/admin/SubAdmin')));
const AddSubAdmin = Loadable(lazy(() => import('../pages/admin/AddSubAdmin')));
const AdminDashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));
const AdminTask = Loadable(lazy(() => import('../pages/admin/Task')));
const AdminCompany = Loadable(lazy(() => import('../pages/admin/Company')));
const Contractor = Loadable(lazy(() => import('../pages/admin/Contractor')));
const AdminContact = Loadable(lazy(() => import('../pages/admin/Contact')));
const AdminLocation = Loadable(lazy(() => import('../pages/admin/Location')));
const AdminCredential = Loadable(lazy(() => import('../pages/admin/Credential')));
const LocationAccess = Loadable(lazy(() => import('../pages/admin/LocationAccess')));
const AdminAssets = Loadable(lazy(() => import('../pages/admin/Assets')));
const AdminCustomerApproval = Loadable(lazy(() => import('../pages/admin/Customerapproval')));
const AdminContractorApproval = Loadable(lazy(() => import('../pages/admin/ContractorApproval')));
const AdminJobTable = Loadable(lazy(() => import('../pages/admin/AdminJobTable')));
const AdminJobEntryForm = Loadable(lazy(() => import('../pages/admin/AdminJobEntryForm')));
const AdminJobEntryFormUpdate = Loadable(lazy(() => import('../pages/admin/AdminJobEntryFormUpdate')));
const AdminService = Loadable(lazy(() => import('../pages/admin/AdminServiceEntry')));
const AdminServiceUpdate = Loadable(lazy(() => import('../pages/admin/AdminServiceEntryUpdate')));
const AdminMyResource = Loadable(lazy(() => import('../pages/admin/MyResource')));
const AdminOnBoarding = Loadable(lazy(() => import('../pages/admin/AdminOnBoarding')));
const AdminSalesLeads = Loadable(lazy(() => import('../pages/admin/AdminSalesLeads')));
const AdminReviewDataUpdate = Loadable(lazy(() => import('../pages/admin/AdminReviewDataUpdate')));
const AdminReviewUploads = Loadable(lazy(() => import('../pages/admin/AdminReviewUploads')));
const AdminViewUploadFile = Loadable(lazy(() => import('../pages/admin/AdminViewUploadFile')));
const AdminSchedulesServices = Loadable(lazy(() => import('../pages/admin/AdminSchedulesServices')));
const OnboardingList = Loadable(lazy(() => import('../pages/admin/OnboardingList')));
const AdminMyTask = Loadable(lazy(() => import('../pages/admin/AdminMyTask')));
const AdminMyTaskCompleted = Loadable(lazy(() => import('../pages/admin/AdminMyTaskCompleted')));
const ElevatorTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/ElevatorTask')));
const ElevatorTaskCompleted = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/ElevatorCompletedTasks')));
const FireDepartmentTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FireDepartmentTask')));
const FireDepartmentCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FireDepartmentCompletedTask')));
const FireAlarmTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FireAlarmTask')));
const FireAlarmCompletedTasks = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FireAlarmCompletedTasks')));
const WetSprinklerTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/WetSprinklerTask')));
const WetSprinklerCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/WetSprinklerCompletedTask')));
const DrySprinklerTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/DrySprinklerTask')));
const DrySprinklerCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/DrySprinklerCompletedTask')));
const PreActionTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/PreActionTask')));
const PreActionCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/PreActionCompletedTask')));
const DelugeTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/DelugeTask')));
const DelugeCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/DelugeCompletedTask')));
const StandpipeTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/StandpipeTask')));
const StandpipeCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/StandpipeCompletedTask')));
const FirePumpTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FirePumpTask')));
const FirePumpCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FirePumpCompletedTask')));
const KitchenHoodTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/KitchenHoodTask')));
const KitchenHoodCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/KitchenHoodCompletedTask')));
const DryChemicalTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/DryChemicalTask')));
const DryChemicalCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/DryChemicalCompletedTask')));
const CleanAgentTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/CleanAgentTask')));
const CleanAgentCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/CleanAgentCompletedTask')));
const FireEscapeTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FireEscapeTask')));
const FireEscapeCompleteTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/FireEscapeCompleteTask')));
const BackFlowTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/BackFlowTask')));
const BackFlowCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/BackFlowCompletedTask')));
const OtherLocationTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/OtherLocationTask')));
const OtherLocationCompletedTask = Loadable(lazy(() => import('../sections/@Ourtask/@Mytask/OtherLocationCompletedTask')));
const AddContractor = Loadable(lazy(() => import('../sections/@Contractor/ConCompanyForm')));
const AddCustomer = Loadable(lazy(() => import('../sections/@Customer/CusCompanyForm')));
const ContractorCompanyInfo = Loadable(lazy(() => import('../pages/admin/ContractorCompanyInfo')));
const ContractorContactInfo = Loadable(lazy(() => import('../pages/admin/ContractorContactInfo')));
const ContractorContactForm = Loadable(lazy(() => import('../sections/@Contractor/ContractorContacatForm')));
const ContractorContactFormAdd = Loadable(lazy(() => import('../sections/@Contractor/ContractorContacatFormAdd')));

// Customer 
const CompLocTable = Loadable(lazy(() => import('../sections/@Admin/CompLocTable')));
const CustomerCompanyInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/CustomerCompanyInfo')));
const UserContactInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/UserContactInfo')));
const LocationInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationInfo')));
// new contactlocation pages
const CusLocationContact = Loadable(lazy(() => import('../pages/customer/ourcompany/CusLocationContact')));
const CusLocationContactForm = Loadable(lazy(() => import('../pages/customer/ourcompany/CusLocationContactForm')));
// 
const DeficiencyEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/DeficiencyEntryForm')));
const JobEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/JobEntryForm')));
const LocationAssetInventory = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetInventory')));
const ServiceEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/ServiceEntryForm')));
const CustomerLocation = Loadable(lazy(() => import('../pages/customer/Location')));
const CustomerElevator = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/Elevator')));
const CustomerFireDep = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/FireDeparte')));
const CustomerKeyLogs = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/KeyLogs')));
const CustomerFireAlarmAsset = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireAlarmAsset')));
const CustomerWetsprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Wetsprinkler')));
const DrySprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DrySprinkler')));
const Preaction = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Preaction')));
const Deluge = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DelugeSprinkler')));
const Standpipe = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/StandpipeSprinkler')));
const Firepumpsprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Firepumpsprinkler')));
const FireHydrant = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireHydrantsprinkler')));
const KitchenHoodSprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/KitchenHoodSprinkler')));
const DryChemical = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DryChemicalSprinkler')));
const CleanAgent = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/CleanAgentSprinkler')));
const FireEscape = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/EscapeSprinkler')));
const FireExtinguisher = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireExtinguisherSprinkler')));
const Backflow = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/BackflowSprinkler')));
const OtherSprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/OtherSprinkler')));
const CompanyLocationQuantity = Loadable(lazy(() => import('../pages/customer/ourcompany/CompanyLocationQuantity')));
const CustomerSalesleads = Loadable(lazy(() => import('../pages/customer/Salesleads')));
const CustomerOnboardings = Loadable(lazy(() => import('../pages/customer/Onboardings')));
const CustomerReviewdataupdates = Loadable(lazy(() => import('../pages/customer/Reviewdataupdates')));
const CustomerReviewuploads = Loadable(lazy(() => import('../pages/customer/Reviewuploads')));
const CustomerScheduleservices = Loadable(lazy(() => import('../pages/customer/Scheduleservices')));
const CuDeficiencyEntryForm = Loadable(lazy(() => import('../sections/@Customer/CuDeficiencyEntryForm')));
const ServiceEntry = Loadable(lazy(() => import('../pages/admin/ServicesEntry')));

// Super Admin
const SuperDashboard = Loadable(lazy(() => import('../pages/superadmin/Dashboard')));
const Company = Loadable(lazy(() => import('../pages/superadmin/Company')));
const Contact = Loadable(lazy(() => import('../pages/superadmin/Contact')));
const Location = Loadable(lazy(() => import('../pages/superadmin/Location')));
const Credential = Loadable(lazy(() => import('../pages/superadmin/Credential')));
const Assets = Loadable(lazy(() => import('../pages/superadmin/Assets')));
const CustomerFeedBack = Loadable(lazy(() => import('../pages/superadmin/CustomerFeedBack')));
const UserProfile = Loadable(lazy(() => import('../pages/superadmin/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/superadmin/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/superadmin/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/superadmin/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/superadmin/UserCreate')));
const SACustomerApproval = Loadable(lazy(() => import('../pages/superadmin/CustomerApproval')));
const ContractorApproval = Loadable(lazy(() => import('../pages/superadmin/ContractorApproval')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
