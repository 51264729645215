/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  message: null,
  error: null,
  // Customer States
  locationloading: false,
  company: [],
  CompanyCustomer: [],
  contact: [],
  location: [],
  service: [],
  jobentery: [],
  elevator: [],
  bilal: '',
  delugeisolation: [],
  delugenameplate: [],
  delugehead: [],
  delugeguage: [],
  delugedrain: [],
  keylogs: [],
  firedep: [],
  firealarm: [],
  drysprinkler: [],
  wetsprinkler: [],
  firepump: [],
  preaction: [],
  deluge: [],
  standpipe: [],
  firehydrant: [],
  kitchenhood: [],
  drychemical: [],
  cleanagent: [],
  fireescape: [],
  fireextinguisher: [],
  backflow: [],
  otherlocation: [],
  batterydetail: [],
  ductdetector: [],
  heatdetector: [],
  supervisory: [],
  flowswitch: [],
  wetantifreeze: [],
  wetguage: [],
  wetnameplate: [],
  wetisolation: [],
  wetdrain: [],
  wethead: [],
  dryantifreeze: [],
  dryguage: [],
  drynameplate: [],
  dryisolation: [],
  drydrain: [],
  dryhead: [],
  preantifreeze: [],
  preguage: [],
  prenameplate: [],
  preisolation: [],
  predrain: [],
  prehead: [],
  spguage: [],
  spisolation: [],
  fpguage: [],
  fpisolation: [],
  khcylinderdetail: [],
  cacylinderdetail: [],
  cylinderdetail: [],
  fhdetail: [],
  fedetail: [],
  bfisolation: [],
  deficiencies: [],
  uploads: [],

};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  // Global Methoed
  LOGIN: (state, action) => {
    const { user, message, error } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      message,
      error
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    message: null,
    error: null,
    // Customer States
    company: null,
    CompanyCustomer: null,
    contact: null,
    location: null,
    service: null,
    jobentery: null,
    elevator: null,
    keylogs: null,
    firedep: null,
    firealarm: null,
    drysprinkler: null,
    wetsprinkler: null,
    firepump: null,
    preaction: null,
    deluge: null,
    standpipe: null,
    firehydrant: null,
    kitchenhood: null,
    drychemical: null,
    cleanagent: null,
    fireescape: null,
    fireextinguisher: null,
    backflow: null,
    otherlocation: null,
    batterydetail: null,
    ductdetector: null,
    heatdetector: null,
    supervisory: null,
    flowswitch: null,
    wetantifreeze: null,
    wetguage: null,
    wetnameplate: null,
    wetisolation: null,
    wetdrain: null,
    wethead: null,
    dryantifreeze: null,
    dryguage: null,
    drynameplate: null,
    dryisolation: null,
    drydrain: null,
    dryhead: null,
    preantifreeze: null,
    preguage: null,
    prenameplate: null,
    preisolation: null,
    predrain: null,
    prehead: null,
    spguage: null,
    spisolation: null,
    fpguage: null,
    fpisolation: null,
    khcylinderdetail: null,
    cacylinderdetail: null,
    cylinderdetail: null,
    fhdetail: null,
    fedetail: null,
    bfisolation: null,
    deficiencies: null,
  }),
  REGISTER: (state, action) => {
    const { user, message, error } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
      message,
      error
    };
  },
  RESETPASSWORD: (state, action) => {
    // 
    const { message } = action.payload;
    return {
      ...state,
      message
    };
  },
  VERIFYPASSWORD: (state, action) => {
    // 
    const { message } = action.payload;
    return {
      ...state,
      message
    };

  },
  // SuBAdmin Methods
  SUBADMINREGISTER: (state, action) => {

    const { contact, message, error } = action.payload;
    return {
      ...state,
      contact,
      message,
      error
    };
  },
  // Customer Method
  COMPANYREGISTER: (state, action) => {

    const { company, message, error } = action.payload;
    return {
      ...state,
      company,
      message,
      error
    };
  },
  COMPANYCONTACT: (state, action) => {

    const { contact, message, error } = action.payload;
    return {
      ...state,
      contact,
      message,
      error
    };
  },
  CONTRACTORCONTACT: (state, action) => {

    const { contact, message, error } = action.payload;
    return {
      ...state,
      contact,
      message,
      error
    };
  },
  DOCUMENTFORM: (state, action) => {

    const { uploads, message, error } = action.payload;
    return {
      ...state,
      uploads,
      message,
      error
    };
  },
  COMPANYLOCATION: (state, action) => {

    const { location, message, error } = action.payload;
    return {
      ...state,
      location,
      message,
      error
    };
  },
  COMPANYSERVICE: (state, action) => {

    const { service, message, error } = action.payload;
    return {
      ...state,
      service,
      message,
      error
    };
  },
  COMPANYJOB: (state, action) => {

    const { jobentery, message, error } = action.payload;
    return {
      ...state,
      jobentery,
      message,
      error
    };
  },
  COMPANYELEVATOR: (state, action) => {

    const { elevator, message, error } = action.payload;
    return {
      ...state,
      elevator,
      message,
      error
    };
  },
  COMPANYBATTERYDETAIL: (state, action) => {

    const { batterydetail, message, error } = action.payload;
    return {
      ...state,
      batterydetail,
      message,
      error
    };
  },
  COMPANYDUCTDETECTOR: (state, action) => {

    const { ductdetector, message, error } = action.payload;
    return {
      ...state,
      ductdetector,
      message,
      error
    };
  },
  COMPANYHEATDETECTOT: (state, action) => {

    const { heatdetector, message, error } = action.payload;
    return {
      ...state,
      heatdetector,
      message,
      error
    };
  },
  COMPANYSUPERVISORY: (state, action) => {

    const { supervisory, message, error } = action.payload;
    return {
      ...state,
      supervisory,
      message,
      error
    };
  },
  COMPANYFLOWSWITCH: (state, action) => {

    const { flowswitch, message, error } = action.payload;
    return {
      ...state,
      flowswitch,
      message,
      error
    };
  },
  COMPANYWETANTIFREEZE: (state, action) => {

    const { wetantifreeze, message, error } = action.payload;
    return {
      ...state,
      wetantifreeze,
      message,
      error
    };
  },

  COMPANYWETGUAGE: (state, action) => {

    const { wetguage, message, error } = action.payload;
    return {
      ...state,
      wetguage,
      message,
      error
    };
  },
  COMPANYNWETAMEPLATE: (state, action) => {

    const { wetnameplate, message, error } = action.payload;
    return {
      ...state,
      wetnameplate,
      message,
      error
    };
  },
  COMPANYWETISOLATION: (state, action) => {

    const { wetisolation, message, error } = action.payload;
    return {
      ...state,
      wetisolation,
      message,
      error
    };
  },
  COMPANYWETDRAIN: (state, action) => {

    const { wetdrain, message, error } = action.payload;
    return {
      ...state,
      wetdrain,
      message,
      error
    };
  },
  COMPANYWETHEAD: (state, action) => {

    const { wethead, message, error } = action.payload;
    return {
      ...state,
      wethead,
      message,
      error
    };
  },
  COMPANYDRYANTIFREEZE: (state, action) => {

    const { dryantifreeze, message, error } = action.payload;
    return {
      ...state,
      dryantifreeze,
      message,
      error
    };
  },
  COMPANYDRYGUAGE: (state, action) => {

    const { dryguage, message, error } = action.payload;
    return {
      ...state,
      dryguage,
      message,
      error
    };
  },
  COMPANYDRYNAMEPLATE: (state, action) => {

    const { drynameplate, message, error } = action.payload;
    return {
      ...state,
      drynameplate,
      message,
      error
    };
  },
  COMPANYDRYISOLATION: (state, action) => {

    const { dryisolation, message, error } = action.payload;
    return {
      ...state,
      dryisolation,
      message,
      error
    };
  },
  COMPANYDRYDRAIN: (state, action) => {

    const { drydrain, message, error } = action.payload;
    return {
      ...state,
      drydrain,
      message,
      error
    };
  },
  COMPANYDRYHEAD: (state, action) => {

    const { dryhead, message, error } = action.payload;
    return {
      ...state,
      dryhead,
      message,
      error
    };
  },

  COMPANYPREANTIFREEZE: (state, action) => {

    const { preantifreeze, message, error } = action.payload;
    return {
      ...state,
      preantifreeze,
      message,
      error
    };
  },
  COMPANYPREGUAGE: (state, action) => {

    const { preguage, message, error } = action.payload;
    return {
      ...state,
      preguage,
      message,
      error
    };
  },
  COMPANYPRENAMEPLATE: (state, action) => {

    const { prenameplate, message, error } = action.payload;
    return {
      ...state,
      prenameplate,
      message,
      error
    };
  },
  COMPANYPREISOLATION: (state, action) => {

    const { preisolation, message, error } = action.payload;
    return {
      ...state,
      preisolation,
      message,
      error
    };
  },
  COMPANYPREDRAIN: (state, action) => {

    const { predrain, message, error } = action.payload;
    return {
      ...state,
      predrain,
      message,
      error
    };
  },
  COMPANYPREHEAD: (state, action) => {

    const { prehead, message, error } = action.payload;
    return {
      ...state,
      prehead,
      message,
      error
    };
  },

  COMPANYKHCYLINDERDETAIL: (state, action) => {

    const { khcylinderdetail, message, error } = action.payload;
    return {
      ...state,
      khcylinderdetail,
      message,
      error
    };
  },
  COMPANYCYLINDERDETAIL: (state, action) => {

    const { cylinderdetail, message, error } = action.payload;
    return {
      ...state,
      cylinderdetail,
      message,
      error
    };
  },

  COMPANYCACYLINDERDETAIL: (state, action) => {

    const { cacylinderdetail, message, error } = action.payload;
    return {
      ...state,
      cacylinderdetail,
      message,
      error
    };
  },

  COMPANYFHDETAIL: (state, action) => {

    const { fhdetail, message, error } = action.payload;
    return {
      ...state,
      fhdetail,
      message,
      error
    };
  },
  COMPANYFEDETAIL: (state, action) => {

    const { fedetail, message, error } = action.payload;
    return {
      ...state,
      fedetail,
      message,
      error
    };
  },
  COMPANYSTANDGUAGE: (state, action) => {

    const { spguage, message, error } = action.payload;
    return {
      ...state,
      spguage,
      message,
      error
    };
  },
  COMPANYSTANDISOLATION: (state, action) => {

    const { spisolation, message, error } = action.payload;
    return {
      ...state,
      spisolation,
      message,
      error
    };
  },
  COMPANYFPGUAGE: (state, action) => {

    const { fpguage, message, error } = action.payload;
    return {
      ...state,
      fpguage,
      message,
      error
    };
  },
  COMPANYFPISOLATION: (state, action) => {

    const { fpisolation, message, error } = action.payload;
    return {
      ...state,
      fpisolation,
      message,
      error
    };
  },
  COMPANYBFISOLATION: (state, action) => {

    const { bfisolation, message, error } = action.payload;
    return {
      ...state,
      bfisolation,
      message,
      error
    };
  },
  COMPANYKEYLOGS: (state, action) => {

    const { keylogs, message, error } = action.payload;
    return {
      ...state,
      keylogs,
      message,
      error
    };
  },
  COMPANYFIREDEP: (state, action) => {

    const { firedep, message, error } = action.payload;
    return {
      ...state,
      firedep,
      message,
      error
    };
  },
  COMPANYFIREALARM: (state, action) => {

    const { firealarm, message, error } = action.payload;
    return {
      ...state,
      firealarm,
      message,
      error
    };
  },
  COMPANYDRYSPRINKLER: (state, action) => {

    const { drysprinkler, message, error } = action.payload;
    return {
      ...state,
      drysprinkler,
      message,
      error
    };
  },
  COMPANYWETSPRINKLER: (state, action) => {

    const { wetsprinkler, message, error } = action.payload;
    return {
      ...state,
      wetsprinkler,
      message,
      error
    };
  },
  COMPANYFIREPUMP: (state, action) => {

    const { firepump, message, error } = action.payload;
    return {
      ...state,
      firepump,
      message,
      error
    };
  },
  COMPANYPREACTION: (state, action) => {

    const { preaction, message, error } = action.payload;
    return {
      ...state,
      preaction,
      message,
      error
    };
  },

  COMPANYDELUGE: (state, action) => {

    const { deluge, message, error } = action.payload;
    return {
      ...state,
      deluge,
      message,
      error
    };
  },

  // Turabi Sub Form Of Delugue
  DELUGEISOLATIONFORM: (state, action) => {

    const { delugeisolation, message, error } = action.payload;
    return {
      ...state,
      delugeisolation,
      message,
      error
    };
  },

  DELEUGEHYDRAULICADD: (state, action) => {
    const { delugenameplate, message, error } = action.payload;
    return {
      ...state,
      delugenameplate,
      message,
      error
    }
  },

  DELEUGEHEAD: (state, action) => {
    const { delugehead, message, error } = action.payload;
    return {
      ...state,
      delugehead,
      message,
      error
    }
  },

  DELEUGEGUAGE: (state, action) => {
    const { delugeguage, message, error } = action.payload;
    return {
      ...state,
      delugeguage,
      message,
      error
    }
  },

  DELEUGEDRAIN: (state, action) => {
    const { delugedrain, message, error } = action.payload;
    return {
      ...state,
      delugedrain,
      message,
      error
    }
  },


  // 

  COMPANYSTANDPIPE: (state, action) => {

    const { standpipe, message, error } = action.payload;
    return {
      ...state,
      standpipe,
      message,
      error
    };
  },
  COMPANYFIREHYDRANT: (state, action) => {

    const { firehydrant, message, error } = action.payload;
    return {
      ...state,
      firehydrant,
      message,
      error
    };
  },
  COMPANYKITCHENHOOD: (state, action) => {

    const { kitchenhood, message, error } = action.payload;
    return {
      ...state,
      kitchenhood,
      message,
      error
    };
  },

  COMPANYCLEANAGENT: (state, action) => {

    const { cleanagent, message, error } = action.payload;
    return {
      ...state,
      cleanagent,
      message,
      error
    };
  },
  COMPANYDRYCHEMICAL: (state, action) => {

    const { drychemical, message, error } = action.payload;
    return {
      ...state,
      drychemical,
      message,
      error
    };
  },
  COMPANYFIREESCAPE: (state, action) => {

    const { fireescape, message, error } = action.payload;
    return {
      ...state,
      fireescape,
      message,
      error
    };
  },
  COMPANYFIREEXTINGUISHER: (state, action) => {

    const { fireextinguisher, message, error } = action.payload;
    return {
      ...state,
      fireextinguisher,
      message,
      error
    };
  },
  DEFICIENCIES: (state, action) => {

    const { deficiencies, message, error } = action.payload;
    return {
      ...state,
      deficiencies,
      message,
      error
    };
  },
  COMPANYBACKFLOW: (state, action) => {

    const { backflow, message, error } = action.payload;
    return {
      ...state,
      backflow,
      message,
      error
    };
  },
  COMPANYOTHERLOCATION: (state, action) => {

    const { otherlocation, message, error } = action.payload;
    return {
      ...state,
      otherlocation,
      message,
      error
    };
  },
  COMPANYDATA: (state, action) => {
    // 
    const { company, contact, location, service, jobentery, elevator, keylogs, firedep, firealarm, drysprinkler, wetsprinkler, firepump, preaction, deluge, standpipe, firehydrant, kitchenhood, cleanagent, drychemical, fireescape, fireextinguisher, backflow, otherlocation, batterydetail, ductdetector, heatdetector, supervisory, flowswitch, wetantifreeze, wetguage, wetnameplate, wetisolation, wetdrain, wethead, dryantifreeze, dryguage, drynameplate, dryisolation, drydrain, dryhead, delugeisolation,
      delugenameplate, delugedrain, preantifreeze, preguage, prenameplate, preisolation, predrain, prehead, spguage, spisolation, fpguage, fpisolation, khcylinderdetail, cacylinderdetail, cylinderdetail, fhdetail, fedetail, bfisolation, delugehead, delugeguage } = action.payload;
    return {
      ...state,
      company,
      contact,
      location,
      service,
      jobentery,
      elevator,
      keylogs,
      firedep,
      firealarm,
      drysprinkler,
      wetsprinkler,
      firepump,
      preaction,
      deluge,
      standpipe,
      firehydrant,
      kitchenhood,
      cleanagent,
      drychemical,
      fireescape,
      fireextinguisher,
      backflow,
      otherlocation,
      batterydetail,
      ductdetector,
      heatdetector,
      supervisory,
      flowswitch,
      wetantifreeze,
      wetguage,
      wetnameplate,
      wetisolation,
      wetdrain,
      wethead,
      dryantifreeze,
      dryguage,
      drynameplate,
      dryisolation,
      drydrain,
      dryhead,
      delugeisolation,
      delugenameplate,
      delugedrain,
      preantifreeze,
      preguage,
      prenameplate,
      preisolation,
      predrain,
      prehead,
      spguage,
      spisolation,
      fpguage,
      fpisolation,
      khcylinderdetail,
      cacylinderdetail,
      cylinderdetail,
      fhdetail,
      fedetail,
      bfisolation,
      delugehead,
      delugeguage,

    };
  },
  LOCATIONFORM: (state, action) => {
    console.log('payload received ->', action.payload);
    const { locationform, message, error } = action.payload;
    return {
      ...state,
      locationform,
      message,
      error
    };
  },

  COMPANYCUSTOMERDATA: (state, action) => {

    console.log("action.payload ",action.payload)
    // 
    const { company , message, error } = action.payload;
    return {
      ...state,
      CompanyCustomer:company,
    };
  },

  LoadingCheck: (state, action) => {
    const { locationloading } = action.payload
    return {
      ...state,
      locationloading
    }
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  // Global Api
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  ChangePath: () => Promise.resolve(),
  resetpassword: () => Promise.resolve(),
  verifypassword: () => Promise.resolve(),
  // Contractor Api
  contractorcompanyinfo: () => Promise.resolve(),

  // Coustomer Api
  companyupdatebelldetector: () => Promise.resolve(),
companybelldetector: () => Promise.resolve(),
  companyregister: () => Promise.resolve(),
  companyupdate: () => Promise.resolve(),
  customercompany: () => Promise.resolve(),
  getLoactionandCustomerData: () => Promise.resolve(),
  customercompanygetapi: () => Promise.resolve(),
  companycontact: () => Promise.resolve(),
  companycontactupdate: () => Promise.resolve(),
  contractorcontact: () => Promise.resolve(),
  contractorcontactupdate: () => Promise.resolve(),
  companylocation: () => Promise.resolve(),
  companylocationupdate: () => Promise.resolve(),
  customercompanygetpost:()=>Promise.resolve(),
  companyservice: () => Promise.resolve(),
  companyserviceupdate: () => Promise.resolve(),
  companyjob: () => Promise.resolve(),
  companyjobupdate: () => Promise.resolve(),
  companyelevator: () => Promise.resolve(),
  companyelevatorupdate: () => Promise.resolve(),
  companykeylogs: () => Promise.resolve(),
  updatecompanykeylogs: () => Promise.resolve(),
  companyfiredep: () => Promise.resolve(),
  updatecompanyfiredep: () => Promise.resolve(),
  firealarmregister: () => Promise.resolve(),
  updatefirealarmregister: () => Promise.resolve(),
  drysprinklerregister: () => Promise.resolve(),
  updatedrysprinklerregister: () => Promise.resolve(),
  wetsprinklerregister: () => Promise.resolve(),
  updatewetsprinklerregister: () => Promise.resolve(),
  firepumpregister: () => Promise.resolve(),
  updatefirepumpregister: () => Promise.resolve(),
  GetDocumentData: () => Promise.resolve(),
  preaction: () => Promise.resolve(),
  delugeregister: () => Promise.resolve(),
  updatedelugeregister: () => Promise.resolve(),
  standpiperegister: () => Promise.resolve(),
  updatestandpiperegister: () => Promise.resolve(),
  firehydrantregister: () => Promise.resolve(),
  updatefirehydrantregister: () => Promise.resolve(),
  kitchenhoodregister: () => Promise.resolve(),
  updatekitchenhoodregister: () => Promise.resolve(),
  cleanagentregister: () => Promise.resolve(),
  updatecleanagentregister: () => Promise.resolve(),
  drychemicalregister: () => Promise.resolve(),
  updatedrychemicalregister: () => Promise.resolve(),
  fireescape: () => Promise.resolve(),
  fireextinguisher: () => Promise.resolve(),
  backflow: () => Promise.resolve(),
  otherlocation: () => Promise.resolve(),
  companybatterydetail: () => Promise.resolve(),
  companyupdatebatterydetail: () => Promise.resolve(),
  companyductdetector: () => Promise.resolve(),
  companyupdateductdetector: () => Promise.resolve(),
  companyheatdetector: () => Promise.resolve(),
  companyupdateheatdetector: () => Promise.resolve(),
  companyflowswitch: () => Promise.resolve(),
  companyupdateflowswitch: () => Promise.resolve(),
  companysupervisory: () => Promise.resolve(),
  companyupdatesupervisory: () => Promise.resolve(),
  companywetantifreeze: () => Promise.resolve(),
  companyupdatewetantifreeze: () => Promise.resolve(),
  companywetguage: () => Promise.resolve(),
  companyupdatewetguage: () => Promise.resolve(),
  companywetnameplate: () => Promise.resolve(),
  companyupdatewetnameplate: () => Promise.resolve(),
  companywetisolation: () => Promise.resolve(),
  companyupdatewetisolation: () => Promise.resolve(),
  companywetdrain: () => Promise.resolve(),
  companyupdatewetdrain: () => Promise.resolve(),
  companywethead: () => Promise.resolve(),
  companyupdatewethead: () => Promise.resolve(),
  companydryantifreeze: () => Promise.resolve(),
  companydryguage: () => Promise.resolve(),
  companyupdatedryguage: () => Promise.resolve(),
  companydrynameplate: () => Promise.resolve(),
  companyupdatedrynameplate: () => Promise.resolve(),
  companydryisolation: () => Promise.resolve(),
  companyupdatedryisolation: () => Promise.resolve(),
  companydrydrain: () => Promise.resolve(),
  companyupdatedrydrain: () => Promise.resolve(),
  companydryhead: () => Promise.resolve(),
  companyupdatedryhead: () => Promise.resolve(),
  companyupdatepullstations: () => Promise.resolve(),
  // Turabi
  delugeisolationadd: () => Promise.resolve(),
  delugeisolationupdate: () => Promise.resolve(),
  deleugehydraulicadd: () => Promise.resolve(),
  deleugehydraulicupdate: () => Promise.resolve(),
  delugeheadadd: () => Promise.resolve(),
  delugeheadupdate: () => Promise.resolve(),
  delugeguageadd: () => Promise.resolve(),
  delugeguageupdate: () => Promise.resolve(),
  delugedrainadd: () => Promise.resolve(),
  delugedrainupdate: () => Promise.resolve(),
  companypreantifreeze: () => Promise.resolve(),
  companypreguage: () => Promise.resolve(),
  companyupdatepreguage: () => Promise.resolve(),
  companyprenameplate: () => Promise.resolve(),
  companyupdateprenameplate: () => Promise.resolve(),
  companypreisolation: () => Promise.resolve(),
  companyupdatepreisolation: () => Promise.resolve(),
  companypredrain: () => Promise.resolve(),
  companyupdatepredrain: () => Promise.resolve(),
  companyprehead: () => Promise.resolve(),
  companyupdateprehead: () => Promise.resolve(),
  companyspguage: () => Promise.resolve(),
  companyupdatespguage: () => Promise.resolve(),
  companyspisolation: () => Promise.resolve(),
  companyupdatespisolation: () => Promise.resolve(),
  companyfpguage: () => Promise.resolve(),
  companyupdatefpguage: () => Promise.resolve(),
  companyfpisolation: () => Promise.resolve(),
  companyupdatefpisolation: () => Promise.resolve(),
  companykhcylinderdetail: () => Promise.resolve(),
  companyupdatekhcylinderdetail: () => Promise.resolve(),
  companycylinderdetail: () => Promise.resolve(),
  companyupdatecylinderdetail: () => Promise.resolve(),
  companycacylinderdetail: () => Promise.resolve(),
  companyupdatecacylinderdetail: () => Promise.resolve(),
  companyfhdetail: () => Promise.resolve(),
  companyupdatefhdetail: () => Promise.resolve(),
  companyfedetail: () => Promise.resolve(),
  companyupdatefedetail: () => Promise.resolve(),
  companybfisolation: () => Promise.resolve(),
  companyupdatebfisolation: () => Promise.resolve(),
  getalldefencie: () => Promise.resolve(),
  preactionregister: () => Promise.resolve(),
  updatepreaction: () => Promise.resolve(),
  fireextinguisherregister: () => Promise.resolve(),
  updatefireextinguisher: () => Promise.resolve(),
  backflowregister: () => Promise.resolve(),
  updatebackflowregister: () => Promise.resolve(),
  fireescaperegister: () => Promise.resolve(),
  updatefireescaperegister: () => Promise.resolve(),
  companyairmaintainance: () => Promise.resolve(),
  updatecompanyairmaintainance: () => Promise.resolve(),

  subadminregister: () => Promise.resolve(),
  customercompanypostapi:()=>Promise.resolve(),
  GetAllData: () => Promise.resolve(),
  companysmokedetector: () => Promise.resolve(),
  companyupdatesmokedetector:()=>Promise.resolve(),
  companyupdatechimesdetail: () => Promise.resolve(),
 companychimesdetail: () => Promise.resolve(),
 companyhornstrobes: () => Promise.resolve(),
 companyhorns: () => Promise.resolve(), 
 companyupdatehorns:()=>Promise.resolve(), 
 companyphotosmokedetector:()=>Promise.resolve(), 
 companyupdatephotosmokedetector:()=>Promise.resolve(), 
 companypullstations:()=>Promise.resolve(),
 companystrobesstrobesdetail:()=>Promise.resolve(),
 companyupdatestrobesstrobesdetail:()=>Promise.resolve(),
 companysubpanels:()=>Promise.resolve(),
 companyupdatesubpanels:()=>Promise.resolve(),
 companyupdatehornstrobes: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pathname, setPathName] = useState('')
  const ChangePath = () => {
    setPathName(window.location.pathname)

  }
  const UserIDs = localStorage.getItem('userID');
  useEffect(() => { ChangePath() }, [window.location.pathname])

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get(`api/data`);
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,

            },
          });
        }
      } catch (err) {
        console.error('error -> ', err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: { id: 1 },
          },
        });

      }
    };

    initialize();
  }, []);

  // Global Apis
  const login = async (email, password) => {
    // 
    // if (pathname === '/superadmin/login') {
    //   // 
    //   const response = await axios.post('/api/superadmin/login', {
    //     email,
    //     password,
    //   });
    //   const { accessToken, user, status, error } = response.data;

    //   if (status === 'success') {
    //     // 
    //     setSession(accessToken);
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         user,
    //         error
    //       },
    //     });
    //   }
    // }
    if (pathname === '/admin/login') {
      // 
      const response = await axios.post('/api/admin/login', {
        email,
        password,
      });
      const { accessToken, user, status, error } = response.data;

      if (status === 'success') {
        // 
        localStorage.setItem('userID', user.id);
        setSession(accessToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            error
          },
        });
      }
    }
    // if (pathname === '/customer/login') {
    //   // 
    //   const response = await axios.post('/api/customer/login', {
    //     email,
    //     password,
    //   });
    //   const { accessToken, user, status, error, message } = response.data;
    //   
    //   // 
    //   if (status === 'success') {
    //     localStorage.setItem('userID', user.id);
    //     localStorage.setItem('user', JSON.stringify(user));
    //     localStorage.setItem('userRole', user.role);
    //     setSession(accessToken);
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         user,
    //         message,
    //         error
    //       },
    //     });
    //   }
    //   if (status === 'failed') {
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         message,
    //         error
    //       },
    //     });
    //   }
    // }
    // if (pathname === '/contractor/login') {
    //   // 
    //   const response = await axios.post('/api/contractor/login', {
    //     email,
    //     password,
    //   });
    //   const { accessToken, user, status, message, error } = response.data;

    //   
    //   if (status === 'success') {
    //     localStorage.setItem('userID', user.id);
    //     setSession(accessToken);
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         user,
    //         message,
    //         error
    //       },
    //     });
    //   }
    //   if (status === 'failed') {
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         message,
    //         error
    //       },
    //     });
    //   }
    // }
    // if (pathname === '/technician/login') {
    //   // 
    //   const response = await axios.post('/api/technician/login', {
    //     email,
    //     password,
    //   });
    //   const { accessToken, user, status, error } = response.data;
    //   if (status === 'success') {
    //     // 
    //     // 
    //     setSession(accessToken);
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         user,
    //         error
    //       },
    //     });
    //   }
    // }
  };
  const companysmokedetector = async ({customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {


    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmsmokedetector', formData);
    const { smokedetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('smokedetectorID', smokedetector.id);
    dispatch({
      type: 'COMPANYSMOKEDETECTOR',
      payload: {
        smokedetector,
        message,
        error
      },
    });
  };



  const companyupdatesmokedetector = async ({id,customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {


      const formData = new FormData();
      formData.append("customerid", customerid)
      formData.append("locationid", locationid)
      formData.append("firealarmid", firealarmid)
      formData.append("photo", photo)
      formData.append("make", make)
      formData.append("model", model)
      formData.append("area_covered", area_covered)
      formData.append("date_installed", date_installed)
      formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmsmokedetector/${id}`,  formData);
    const { smokedetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('smokedetectorID', smokedetector.id);
    dispatch({
      type: 'COMPANYSMOKEDETECTOR',
      payload: {
        smokedetector,
        message,
        error
      },
    });
  };

  const register = async (email, password, firstname, lastname) => {
    if (pathname === '/contractor/register') {
      const response = await axios.post('/api/contractor/register', {
        email,
        password,
        firstname,
        lastname,
      });
      const { user, message, error } = response.data;
      // 
      // window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
          message,
          error
        },
      });
    }
    if (pathname === '/customer/register') {
      const response = await axios.post('/api/customer/register', {
        email,
        password,
        firstname,
        lastname,
      });
      const { user, message, error } = response.data;
      // 
      // window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
          message,
          error
        },
      });
    }

  };

  const logout = async () => {
    return new Promise((resolve, reject) => {
      setSession(null);
      localStorage.clear();
      dispatch({ type: 'LOGOUT' });
      resolve({ status: true })
    })
  };

  const resetpassword = async (email) => {
   
    console.log(email,"email")

    const response = await axios.post(`api/admin/forgotpassword`, { email });
      const { message, error } = response.data;
      console.log(message,"message")
      dispatch({
        type: 'RESETPASSWORD',
        payload: {
          message,
          error
        },
      });

  }
  const companyairmaintainance = async (customerid, locationid, locationatsite, compressormake, compressormodel, serialnumber, bornondate, assetcondition, capacity, tank, airmaintenancedevice, airmaintenancemake, airmaintenancemodel, fittingsize, voltage, horsepower,compressorname) => {
    const response = await axios.post('api/customer/addaircompressor', {
      customerid, locationid, locationatsite, compressormake, compressormodel, serialnumber, bornondate, assetcondition, capacity, tank, airmaintenancedevice, airmaintenancemake, airmaintenancemodel, fittingsize, voltage, horsepower,compressorname
    });
    const { aircompressor, error, message } = response.data;
    // console.log('response after api hit ->',)
    // localStorage.setItem('elevatorID', elevator.id);
    // dispatch({
    //   type: 'COMPANYELEVATOR',
    //   payload: {
    //     elevator,
    //     message,
    //     error
    //   },
    // });
  };

  const updatecompanyairmaintainance = async (id,customerid, locationid, locationatsite, compressormake, compressormodel, serialnumber, bornondate, assetcondition, capacity, tank, airmaintenancedevice, airmaintenancemake, airmaintenancemodel, fittingsize, horsepower, voltage,compressorname) => {
    const response = await axios.post(`api/customer/updateaircompressor/${id}`, {
      customerid, locationid, locationatsite, compressormake, compressormodel, serialnumber, bornondate, assetcondition, capacity, tank, airmaintenancedevice, airmaintenancemake, airmaintenancemodel, fittingsize, horsepower, voltage ,compressorname
    });
    const { aircompressor, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('aircompressorID', aircompressor.id);
    // dispatch({
    //   type: 'COMPANYELEVATOR',
    //   payload: {
    //     aircompressor,
    //     message,
    //     error
    //   },
    // });
  };

  const verifypassword = async (token, password, confirmpassword) => {
    if (pathname === '/customer/verify') {
      const response = await axios.post(`api/customer/resetpassword`, { token, password, confirmpassword });
      const { message, error } = response.data;
      dispatch({
        type: 'VERIFYPASSWORD',
        payload: {
          message,
          error
        },
      });
    }
    if (pathname === '/contractor/verify') {
      const response = await axios.post(`api/contractor/resetpassword`, { token, password, confirmpassword });
      const { message, error } = response.data;
      dispatch({
        type: 'VERIFYPASSWORD',
        payload: {
          message,
          error
        },
      });
    }
  }

  // Sub Admin Api
  const subadminregister = async (firstname, lastname, companyname, email, phone, password, note) => {

    const formData = new FormData();
    formData.append("firstname", firstname)
    formData.append("lastname", lastname)
    formData.append("companyname", companyname)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("password", password)
    formData.append("note", note)
    // return;
    const response = await axios.post('api/superadmin/addsubadmin', formData);
    const { contact, error, message } = response.data;
    dispatch({
      type: 'SUBADMINREGISTER',
      payload: {
        contact,
        message,
        error
      },
    });
  };
  // Customer APIS
  const companyregister = async (customerid, companyname, streetaddress, city, state, suitenumber, zipcode, website, email, phone, fax, notes, logo) => {


    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("companyname", companyname)
    formData.append("streetaddress", streetaddress)
    formData.append("city", city)
    formData.append("state", state)
    formData.append("suitenumber", suitenumber)
    formData.append("zipcode", zipcode)
    formData.append("website", website)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("fax", fax)
    formData.append("notes", notes)
    formData.append("logo", logo)

    // return;

    const response = await axios.post('api/customer/addcompany', formData);
    // 
    const { company, error, message } = response.data;
    localStorage.setItem('companyID', company.id);
    dispatch({
      type: 'COMPANYREGISTER',
      payload: {
        company,
        message,
        error
      },
    });
  };

  const companyupdate = async (id, customerid, companyname, streetaddress, city, state, suitenumber, zipcode, website, email, phone, fax, notes, logo) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("companyname", companyname)
    formData.append("streetaddress", streetaddress)
    formData.append("city", city)
    formData.append("state", state)
    formData.append("suitenumber", suitenumber)
    formData.append("zipcode", zipcode)
    formData.append("website", website)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("fax", fax)
    formData.append("notes", notes)
    formData.append("logo", logo)
    // return;
    const response = await axios.post(`api/customer/updatecompany/${id}`, formData);
    // 
    const { company, error, message } = response.data;
    dispatch({
      type: 'COMPANYREGISTER',
      payload: {
        company,
        message,
        error
      },
    });
  };
  const contractorcontact = async (contractorid, array) => {
    const response = await axios.post('api/superadmin/addcontractorcontactinfo', { contractorid, array });
    const { contact, error, message } = response.data;
    // 
    localStorage.setItem('contactID', contact.id);
    dispatch({
      type: 'CONTRACTORCONTACT',
      payload: {
        array,
        message,
        error
      },
    });
  };
  const contractorcontactupdate = async (id, contractorid, array) => {
    const response = await axios.post(`api/superadmin/updatecontractorcontactinfo/${id}`, { contractorid, array });
    const { contact, error, message } = response.data;
    // 
    localStorage.setItem('contactID', contact.id);
    dispatch({
      type: 'CONTRACTORCONTACT',
      payload: {
        array,
        message,
        error
      },
    });
  };
  const companycontact = async (customerid, firstname, lastname, email, phonecell, officephone, role, description, notes) => {
    const response = await axios.post('api/customer/adduserinfo', { customerid, firstname, lastname, email, phonecell, officephone, role, description, notes });
    const { contact, error, message } = response.data;
    // 
    localStorage.setItem('contactID', contact.id);
    dispatch({
      type: 'COMPANYCONTACT',
      payload: {
        contact,
        message,
        error
      },
    });
  };
  const companycontactupdate = async (id, customerid, firstname, lastname, email, phonecell, officephone, role, description, notes) => {
    const response = await axios.post(`api/customer/updateuserinfo/${id}`, { customerid, firstname, lastname, email, phonecell, officephone, role, description, notes });
    const { contact, error, message } = response.data;
    // 
    localStorage.setItem('contactID', contact.id);
    dispatch({
      type: 'COMPANYCONTACT',
      payload: {
        contact,
        message,
        error
      },
    });
  };
  const companylocation = async (data) => {
    console.log("data in location",data)
    const formData = new FormData();
    formData.append("customerid", data.customeId)
    formData.append("companycontactid", data.companycontactid)
    formData.append("name", data.name)
    formData.append("address", data.address)
    formData.append("city", data.city)
    formData.append("country", data.country)
    formData.append("state", data.state)
    formData.append("zip", data.zip)
    formData.append("floors", data.floors)
    formData.append("floorscount", data.floorscount)
    formData.append("elevator", data.elevator)
    formData.append("elevatorcount", data.elevatorcount)
    formData.append("aircompressor", data.aircompressor)
    formData.append("aircompressorcount", data.aircompressorcount)
    formData.append("resdentialunit", data.resdentialunit)
    formData.append("resdentialunitcount", data.resdentialunitcount)
    formData.append("firedepartmentconnection", data.firedepartmentconnection)
    formData.append("accessnotes", data.accessnotes)
    formData.append("locationkeyquantity", data.locationkeyquantity)
    formData.append("locationnotes", data.locationnotes)
    formData.append("authid",data.authid)
    formData.append("rolename",data.role)
    formData.append("image", data.image)
    formData.append("firealarmcount", data.firealarmcount)
    formData.append("wetsprinklercount", data.wetsprinklercount)
    formData.append("drysprinklercount", data.drysprinklercount)
    formData.append("preactioncount", data.preactioncount)
    formData.append("delugecount", data.delugecount)
    formData.append("standpipecount", data.standpipecount)
    formData.append("firepumpcount", data.firepumpcount)
    formData.append("firehydrantcount", data.firehydrantcount)
    formData.append("kitchenhoodcount", data.kitchenhoodcount)
    formData.append("drychemicalcount", data.drychemicalcount)
    formData.append("cleanagentcount", data.cleanagentcount)
    formData.append("fireescapecount", data.fireescapecount)
    formData.append("fireextingushercount", data.fireextingushercount)
    formData.append("backflowcount", data.backflowcount)
    formData.append("othercount", data.othercount)

    const response = await axios.post('api/customer/addcompanylocation', formData);
    // 
    const { location, error, message } = response.data;
    localStorage.setItem('locationID', location.id);
    dispatch({
      type: 'COMPANYLOCATION',
      payload: {
        location,
        message,
        error
      },
    });
  };
  const companylocationupdate = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customeId)
    formData.append("companycontactid", data.companycontactid)
    formData.append("name", data.name)
    formData.append("address", data.address)
    formData.append("city", data.city)
    formData.append("country", data.country)
    formData.append("state", data.state)
    formData.append("zip", data.zip)
    formData.append("aircompressor", data.aircompressor)
    formData.append("aircompressorcount", data.aircompressorcount)
    formData.append("floors", data.floors)
    formData.append("floorscount", data.floorscount)
    formData.append("elevator", data.elevator)
    formData.append("elevatorcount", data.elevatorcount)
    formData.append("resdentialunit", data.resdentialunit)
    formData.append("resdentialunitcount", data.resdentialunitcount)
    formData.append("firedepartmentconnection", data.firedepartmentconnection)
    formData.append("accessnotes", data.accessnotes)
    formData.append("locationkeyquantity", data.locationkeyquantity)
    formData.append("locationnotes", data.locationnotes)
    formData.append("authid",data.authid)
    formData.append("rolename",data.role)
    formData.append("image", data.image)
    formData.append("firealarmcount", data.firealarmcount)
    formData.append("wetsprinklercount", data.wetsprinklercount)
    formData.append("drysprinklercount", data.drysprinklercount)
    formData.append("preactioncount", data.preactioncount)
    formData.append("delugecount", data.delugecount)
    formData.append("standpipecount", data.standpipecount)
    formData.append("firepumpcount", data.firepumpcount)
    formData.append("firehydrantcount", data.firehydrantcount)
    formData.append("kitchenhoodcount", data.kitchenhoodcount)
    formData.append("drychemicalcount", data.drychemicalcount)
    formData.append("cleanagentcount", data.cleanagentcount)
    formData.append("fireescapecount", data.fireescapecount)
    formData.append("fireextingushercount", data.fireextingushercount)
    formData.append("backflowcount", data.backflowcount)
    formData.append("othercount", data.othercount)

    const response = await axios.post(`api/customer/updatecompanylocation/${data.id}`, formData);
    // 
    const { location, error, message } = response.data;
    localStorage.setItem('locationID', location.id);
    dispatch({
      type: 'COMPANYLOCATION',
      payload: {
        location,
        message,
        error
      },
    });
  };
  const customercompanypostapi = async (id, customeId, companyname, streetaddress, website, phone, officephone, firstname, lastname, notes, email
    ) => {
    const formData = new FormData();
    formData.append("customerid", customeId)
    formData.append("companyname", companyname)
    formData.append("streetaddress", streetaddress)
    formData.append("website", website)
    formData.append("phone", phone)
    formData.append("officephone", officephone)
    formData.append("firstname", firstname)
    formData.append("notes", notes)
    formData.append("lastname", lastname)
    formData.append("email", email)

    const response = await axios.post(`api/superadmin/addcustomercontactinfo/${customeId}`, formData);
    // 
    const { company, error, message } = response.data;
    // localStorage.setItem('locationID', location.id);
    dispatch({
      type: 'COMPANYLOCATION',
      payload: {
        company:company.length>0 ?company[0] : {},
        message,
        error
      },
    });
  };
  const companyservice = async (name,companyid, customerid, locationid, assetid, servicetype, servicefrequency, servicedescription, servicewindow, servicedate, nextscheduledservicedate) => {
    const response = await axios.post('api/customer/addserviceentry', {
      name,
      companyid, 
      customerid, 
      locationid, 
      assetid, 
      servicetype, 
      servicefrequency, 
      servicedescription, 
      servicewindow, 
      servicedate, 
      nextscheduledservicedate
    });
    const { service, error, message } = response.data;
    // 
    localStorage.setItem('serviceID', service.id);
    dispatch({
      type: 'COMPANYSERVICE',
      payload: {
        service,
        message,
        error
      },
    });
  };
  const companyserviceupdate = async (name,customerid, assettype, servicetype, servicefrequency, servicedescription, servicewindow, servicedate, nextscheduledservicedate) => {
    const response = await axios.post(`api/customer/updateserviceentry/${UserIDs}`, {
      name,
      customerid,
      assettype,
      servicetype,
      servicefrequency,
      servicedescription,
      servicewindow,
      servicedate,
      nextscheduledservicedate
    });
    const { service, error, message } = response.data;
    // 
    localStorage.setItem('serviceID', service.id);
    dispatch({
      type: 'COMPANYSERVICE',
      payload: {
        service,
        message,
        error
      },
    });
  };
  const companyjob = async (data ,jobs) => {
      
    console.log("companyjob ==>",jobs)

    let serviceid;
    let description;

      if(data.serviceid
        ){
        serviceid = data.serviesid.map((e)=>e.value)
        description = jobs.map((e)=>e.description)
      }
      
    const response = await axios.post('api/customer/addjobentry', {
      locationid:data.locationid,
      customerid:data.customerid,
      assetid:data.assetid,
      name:data.name,
      jobdescription:data.jobdescription,
      locationservices:data.locationservices,
      jobtype:data.jobtype,
      jobscheduleddate:data.jobscheduleddate,
      jobscheduledstarttime:data.jobscheduledstarttime,
      startingwindow:data.startingwindow,
      contractorid:data.contractorid,
      clockintime:data.clockintime,
      partsused:data.partsused,
      distancefromhomeoffice:data.distancefromhomeoffice,
      anyissues:data.anyissues,
      servicelistcomplete:data.servicelistcomplete,
      clockouttime:data.clockouttime,
      serviesid:() => (serviceid ? serviceid :null),
      descriptions:() => (description ? description :null),
    });
    const { jobentery, error, message } = response.data;
    // 
    localStorage.setItem('jobenteryID', jobentery.id);
    dispatch({
      type: 'COMPANYJOB',
      payload: {
        jobentery,
        message,
        error
      },
    });
  };
  const companyjobupdate = async (customerid, locationname, locationservices, jobtype, jobdescription, jobscheduleddate, jobscheduledstarttime, startingwindow, contractorcompany, scheduledtechnician, technicianphone, clockintime, techniciancountonsite, partsused, distancefromhomeoffice, anyissues, servicelistcomplete, clockouttime) => {
    const response = await axios.post(`api/customer/updatejobentry/${UserIDs}`, {
      customerid,
      locationname,
      locationservices,
      jobtype,
      jobdescription,
      jobscheduleddate,
      jobscheduledstarttime,
      startingwindow,
      contractorcompany,
      scheduledtechnician,
      technicianphone,
      clockintime,
      techniciancountonsite,
      partsused,
      distancefromhomeoffice,
      anyissues,
      servicelistcomplete,
      clockouttime,
    });
    const { jobentery, error, message } = response.data;
    // 
    localStorage.setItem('jobenteryID', jobentery.id);
    dispatch({
      type: 'COMPANYJOB',
      payload: {
        jobentery,
        message,
        error
      },
    });
  };
  const companyelevator = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("elevatornumber", data.elevatornumber)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetbornondate", data.assetbornondate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("floorserviced", data.floorserviced)
    formData.append("primaryrecallfloor", data.primaryrecallfloor)
    formData.append("secondaryrecallfloor", data.secondaryrecallfloor)
    formData.append("schematicprovided", data.schematicprovided)

    const response = await axios.post('api/customer/addelevatordetail', formData);
    // console.log('response after api hit ->', response.data)
    const { elevator, error, message } = response.data;
    localStorage.setItem('elevatorID', elevator.id);
    dispatch({
      type: 'COMPANYELEVATOR',
      payload: {
        elevator,
        message,
        error
      },
    });
  };
  const companyelevatorupdate = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("elevatornumber", data.elevatornumber)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetbornondate", data.assetbornondate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("floorserviced", data.floorserviced)
    formData.append("primaryrecallfloor", data.primaryrecallfloor)
    formData.append("secondaryrecallfloor", data.secondaryrecallfloor)
    formData.append("schematicprovided", data.schematicprovided)

    const response = await axios.post(`api/customer/updateelevatordetail/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { elevator, error, message } = response.data;
    localStorage.setItem('elevatorID', elevator.id);
    dispatch({
      type: 'COMPANYELEVATOR',
      payload: {
        elevator,
        message,
        error
      },
    });
  };
  const companykeylogs = async (customerid,locationid,assetphoto,placardphoto, keyidnumber, keytype, onsitelocation, onsitelockboxcode, stampcode,  notes) => {
    const response = await axios.post('api/customer/addkeylog', { customerid,locationid,assetphoto,placardphoto, keyidnumber, keytype, onsitelocation, onsitelockboxcode, stampcode,  notes });
    const { keylogs, error, message } = response.data;
    // 
    localStorage.setItem('keylogsID', keylogs.id);
    dispatch({
      type: 'COMPANYKEYLOGS',
      payload: {
        keylogs,
        message,
        error
      },
    });
  };
  const updatecompanykeylogs = async (id, customerid, keyidnumber, locationname, keytype, OFPMhavecopy, onsitelocation, onsitelockboxcode, manufacturer, stampcode, notes) => {
    const response = await axios.post(`api/customer/updatekeylog/${id}`, { customerid, keyidnumber, locationname, keytype, OFPMhavecopy, onsitelocation, onsitelockboxcode, manufacturer, stampcode, notes });
    const { keylogs, error, message } = response.data;
    // 
    localStorage.setItem('keylogsID', keylogs.id);
    dispatch({
      type: 'COMPANYKEYLOGS',
      payload: {
        keylogs,
        message,
        error
      },
    });
  };
  const companyfiredep = async (customerid,locationid,assetphoto, placardphoto,fdcshared, fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, snootopenings,notes) => {
    const response = await axios.post('api/customer/addconnectiondetail', { customerid,locationid,assetphoto,placardphoto,fdcshared, fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, snootopenings, notes });
    const { firedep, error, message } = response.data;
    // // 
    // localStorage.setItem('firedepID', firedep.id);
    dispatch({
      type: 'COMPANYFIREDEP',
      payload: {
        firedep,
        message,
        error
      },
    });
  };
  const updatecompanyfiredep = async (id, customerid,locationid,fdcshared,assetphoto,placardphoto,fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, snootopenings, notes) => {
    const response = await axios.post(`api/customer/updateconnectiondetail/${id}`, { customerid,locationid, fdcshared, assetphoto,placardphoto,fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, snootopenings, notes });
    const { firedep, error, message } = response.data;
    // // 
    // localStorage.setItem('firedepID', firedep.id);
    dispatch({
      type: 'COMPANYFIREDEP',
      payload: {
        firedep,
        message,
        error
      },
    });
  };
  const firealarmregister = async (data,locationid) => {
    console.log(data ,locationid)
    const formData = new FormData();
    formData.append("locationid", locationid)
    formData.append("customerid", data?.customerid)
    formData.append("assetid", data?.assetid)
    formData.append("photo", data?.photo)
    formData.append("placardphoto", data?.placardphoto)
    formData.append("assetname", data?.assetname)
    formData.append("location", data?.location)
    formData.append("protect", data?.protect)
    formData.append("make", data?.make)
    formData.append("model", data?.model)
    formData.append("serial", data?.serial)
    formData.append("bron", data?.bron)
    formData.append("installingcontractor", data?.installingcontractor)
    formData.append("servicecontractor", data?.servicecontractor)
    formData.append("distributorcompany", data?.distributorcompany)
    formData.append("ahj", data?.ahj)
    formData.append("condition", data?.condition)
    formData.append("mointeringcompany", data?.mointeringcompany)
    formData.append("panelmoniterd", data?.panelmoniterd)
    formData.append("replacepart", data?.replacepart)
    formData.append("buget", data?.buget)
    formData.append("keyavailble", data?.keyavailble)
    formData.append("keylog", data?.keylog)
    formData.append("backupavalable", data?.backupavalable)
    formData.append("backuplocation", data?.backuplocation)
    formData.append("researchtask", data?.researchtask)
    formData.append("quantitybatteries", data?.quantitybatteries)
    formData.append("paneltype", data?.paneltype)
    formData.append("circuitstyle", data?.circuitstyle)
    formData.append("numberinitiating", data?.numberinitiating)
    formData.append("numbersingnaling", data?.numbersingnaling)
    formData.append("subpanel", data?.subpanel)
    formData.append("pullstation", data?.pullstation)
    formData.append("smokedetector", data?.smokedetector)
    formData.append("numsmokedetector", data?.numsmokedetector)
    formData.append("ductdetector", data?.ductdetector)
    formData.append("heatdetector", data?.heatdetector)
    formData.append("flowswitch", data?.flowswitch)
    formData.append("supervioryswitches", data?.supervioryswitches)
    formData.append("bell", data?.bell)
    formData.append("chimes", data?.chimes)
    formData.append("horn", data?.horn)
    formData.append("strobes", data?.strobes)
    formData.append("hornstrobes", data?.hornstrobes)
    formData.append("contractorid", data?.contractorid)
    formData.append("serviesdate", data?.serviesdate)
    formData.append("nextscheduled", data?.nextscheduled)
    formData.append("note", data?.note)

    const response = await axios.post('api/customer/firealarm', formData);
    // 
    const { firealarm, error, message } = response.data;
    localStorage.setItem('firealarmID', firealarm.id);
    dispatch({
      type: 'COMPANYFIREALARM',
      payload: {
        firealarm,
        message,
        error
      },
    });
  };
  const updatefirealarmregister = async ({id, customerid, assetid, photo, placardphoto, assetname, location, protect, make, model, serial, bron, installingcontractor, servicecontractor, distributorcompany, ahj, condition, mointeringcompany, panelmoniterd, replacepart, buget, keyavailble, keylog, backupavalable, backuplocation, researchtask, quantitybatteries, paneltype, circuitstyle, numberinitiating, numbersingnaling, subpanel, pullstation, smokedetector, numsmokedetector, ductdetector, heatdetector, flowswitch, supervioryswitches, bell, chimes, horn, strobes, hornstrobes, contractorid, serviesdate, nextscheduled, note}) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("photo", photo)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serial", serial)
    formData.append("bron", bron)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("ahj", ahj)
    formData.append("condition", condition)
    formData.append("mointeringcompany", mointeringcompany)
    formData.append("panelmoniterd", panelmoniterd)
    formData.append("replacepart", replacepart)
    formData.append("buget", buget)
    formData.append("keyavailble", keyavailble)
    formData.append("keylog", keylog)
    formData.append("backupavalable", backupavalable)
    formData.append("backuplocation", backuplocation)
    formData.append("researchtask", researchtask)
    formData.append("quantitybatteries", quantitybatteries)
    formData.append("paneltype", paneltype)
    formData.append("circuitstyle", circuitstyle)
    formData.append("numberinitiating", numberinitiating)
    formData.append("numbersingnaling", numbersingnaling)
    formData.append("subpanel", subpanel)
    formData.append("pullstation", pullstation)
    formData.append("smokedetector", smokedetector)
    formData.append("numsmokedetector", numsmokedetector)
    formData.append("ductdetector", ductdetector)
    formData.append("heatdetector", heatdetector)
    formData.append("flowswitch", flowswitch)
    formData.append("supervioryswitches", supervioryswitches)
    formData.append("bell", bell)
    formData.append("chimes", chimes)
    formData.append("horn", horn)
    formData.append("strobes", strobes)
    formData.append("hornstrobes", hornstrobes)
    formData.append("contractorid", contractorid)
    formData.append("serviesdate", serviesdate)
    formData.append("nextscheduled", nextscheduled)
    formData.append("note", note)

    const response = await axios.post(`api/customer/updatefirealarm/${id}`, formData);
    // 
    const { firealarm, error, message } = response.data;
    localStorage.setItem('firealarmID', firealarm.id);
    dispatch({
      type: 'COMPANYFIREALARM',
      payload: {
        firealarm,
        message,
        error
      },
    });
  };
const drysprinklerregister = async (data) => {
    console.log(data)
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)

    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    // formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    formData.append("replacementparts", data.replacementparts)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("hydraulicallydesigned", data.hydraulicallydesigned)
    formData.append("nexttripdue", data.nexttripdue)
    formData.append("aircompressorid", data.aircompressorid)
    formData.append("aircompressormake", data.aircompressormake)
    formData.append("aircompressormodel", data.aircompressormodel)
    formData.append("aircompressorcapacity", data.aircompressorcapacity)
    formData.append("aircompressorhorsepower", data.aircompressorhorsepower)
    formData.append("aircompressorpowersupply", data.aircompressorpowersupply)
    formData.append("aircompressortank", data.aircompressortank)
    formData.append("maintenancedevice", data.maintenancedevice)
    formData.append("maintenancedevicemake", data.maintenancedevicemake)
    formData.append("maintenancedevicemodel", data.maintenancedevicemodel)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", data.servicedate)
    // formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/adddrysprinkler', formData);
    // 
    const { drysprinkler, error, message } = response.data;
    localStorage.setItem('drysprinklerID', drysprinkler.id);
    dispatch({
      type: 'COMPANYDRYSPRINKLER',
      payload: {
        drysprinkler,
        message,
        error
      },
    });
  };
  const updatedrysprinklerregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    // formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    formData.append("replacementparts", data.replacementparts)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("hydraulicallydesigned", data.hydraulicallydesigned)
    formData.append("nexttripdue", data.nexttripdue)
    formData.append("aircompressorid", data.aircompressorid)
    formData.append("aircompressormake", data.aircompressormake)
    formData.append("aircompressormodel", data.aircompressormodel)
    formData.append("aircompressorcapacity", data.aircompressorcapacity)
    formData.append("aircompressorhorsepower", data.aircompressorhorsepower)
    formData.append("aircompressorpowersupply", data.aircompressorpowersupply)
    formData.append("aircompressortank", data.aircompressortank)
    formData.append("maintenancedevice", data.maintenancedevice)
    formData.append("maintenancedevicemake", data.maintenancedevicemake)
    formData.append("maintenancedevicemodel", data.maintenancedevicemodel)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", data.servicedate)
    // formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatedrysprinkler/${data.id}`, formData);
    // 
    const { drysprinkler, error, message } = response.data;
    localStorage.setItem('drysprinklerID', drysprinkler.id);
    dispatch({
      type: 'COMPANYDRYSPRINKLER',
      payload: {
        drysprinkler,
        message,
        error
      },
    });
  };

  const wetsprinklerregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    // formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    // formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("systemhydraulicallydesigned", data.systemhydraulicallydesigned)
    formData.append("antfreezeconnection", data.antfreezeconnection)
    formData.append("antifreezecount", data.antifreezecount)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", data.servicedate)
    // formData.append("nextdate", data.nextdate)

    const response = await axios.post('api/customer/addwetsprinkler', formData);
    // console.log('response after api hit ->', response.data)
    const { wetsprinkler, error, message } = response.data;
    localStorage.setItem('wetsprinklerID', wetsprinkler.id);
    dispatch({
      type: 'COMPANYWETSPRINKLER',
      payload: {
        wetsprinkler,
        message,
        error
      },
    });
  };
  const updatewetsprinklerregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    // formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    // formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("systemhydraulicallydesigned", data.systemhydraulicallydesigned)
    formData.append("antfreezeconnection", data.antfreezeconnection)
    formData.append("antifreezecount", data.antifreezecount)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", data.servicedate)
    // formData.append("nextdate", data.nextdate)

    const response = await axios.post(`api/customer/updatewetsprinkler/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { wetsprinkler, error, message } = response.data;
    localStorage.setItem('wetsprinklerID', wetsprinkler.id);
    dispatch({
      type: 'COMPANYWETSPRINKLER',
      payload: {
        wetsprinkler,
        message,
        error
      },
    });
  };
  const backflowregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("backflowtype", data.backflowtype)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("backflowaboveground", data.backflowaboveground)
    formData.append("chanceforflooding", data.chanceforflooding)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addbackflow', formData);
    // console.log('response after api hit ->', response.data)
    const { backflow, error, message } = response.data;
    localStorage.setItem('backflowID', backflow.id);
    dispatch({
      type: 'COMPANYBACKFLOW',
      payload: {
        backflow,
        message,
        error
      },
    });
  };
  const updatebackflowregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("backflowtype", data.backflowtype)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("backflowaboveground", data.backflowaboveground)
    formData.append("chanceforflooding", data.chanceforflooding)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatebackflow/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { backflow, error, message } = response.data;
    localStorage.setItem('backflowID', backflow.id);
    dispatch({
      type: 'COMPANYBACKFLOW',
      payload: {
        backflow,
        message,
        error
      },
    });
  };

  const firepumpregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("pumptype", data.pumptype)
    formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("pumpcapacity", data.pumpcapacity)
    formData.append("jockeypump", data.jockeypump)
    formData.append("controllermanufacturer", data.controllermanufacturer)
    formData.append("controllermodel", data.controllermodel)
    formData.append("guagequantity", data.guagequantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", data.servicedate)
    // formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addfirepump', formData);
    // console.log('response after api hit ->', response.data)
    const { firepump, error, message } = response.data;
    localStorage.setItem('firepumpID', firepump.id);
    dispatch({
      type: 'COMPANYFIREPUMP',
      payload: {
        firepump,
        message,
        error
      },
    });
  };
  const updatefirepumpregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("pumptype", data.pumptype)
    formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("pumpcapacity", data.pumpcapacity)
    formData.append("jockeypump", data.jockeypump)
    formData.append("controllermanufacturer", data.controllermanufacturer)
    formData.append("controllermodel", data.controllermodel)
    formData.append("guagequantity", data.guagequantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", data.servicedate)
    // formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatefirepump/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { firepump, error, message } = response.data;
    localStorage.setItem('firepumpID', firepump.id);
    dispatch({
      type: 'COMPANYFIREPUMP',
      payload: {
        firepump,
        message,
        error
      },
    });
  };
  const preactionregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    // formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("hydraulicallydesigned", data.hydraulicallydesigned)
    formData.append("nextdate", data.nextdate)
    formData.append("aircompressorid", data.aircompressorid)
    formData.append("aircompressormake", data.aircompressormake)
    formData.append("aircompressormodel", data.aircompressormodel)
    formData.append("aircompressorcapacity", data.aircompressorcapacity)
    formData.append("aircompressorhorsepower", data.aircompressorhorsepower)
    formData.append("aircompressorpowersupply", data.aircompressorpowersupply)
    formData.append("aircompressortank", data.aircompressortank)
    formData.append("maintenancedevice", data.maintenancedevice)
    formData.append("maintenancedevicemake", data.maintenancedevicemake)
    formData.append("maintenancedevicemodel", data.maintenancedevicemodel)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addpreaction', formData);
    // console.log('response after api hit ->', response.data)
    const { preaction, error, message } = response.data;
    localStorage.setItem('preactionID', preaction.id);
    dispatch({
      type: 'COMPANYPREACTION',
      payload: {
        preaction,
        message,
        error
      },
    });
  };

  const updatepreaction = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    // formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("hydraulicallydesigned", data.hydraulicallydesigned)
    formData.append("nextdate", data.nextdate)
    formData.append("aircompressorid", data.aircompressorid)
    formData.append("aircompressormake", data.aircompressormake)
    formData.append("aircompressormodel", data.aircompressormodel)
    formData.append("aircompressorcapacity", data.aircompressorcapacity)
    formData.append("aircompressorhorsepower", data.aircompressorhorsepower)
    formData.append("aircompressorpowersupply", data.aircompressorpowersupply)
    formData.append("aircompressortank", data.aircompressortank)
    formData.append("maintenancedevice", data.maintenancedevice)
    formData.append("maintenancedevicemake", data.maintenancedevicemake)
    formData.append("maintenancedevicemodel", data.maintenancedevicemodel)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatepreaction/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { preaction, error, message } = response.data;
    localStorage.setItem('preactionID', preaction.id);
    dispatch({
      type: 'COMPANYPREACTION',
      payload: {
        preaction,
        message,
        error
      },
    });
  };

  const delugeregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    // formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("hydraulicallydesigned", data.hydraulicallydesigned)
    formData.append("nextdate", data.nextdate)
    formData.append("aircompressorid", data.aircompressorid)
    formData.append("aircompressormake", data.aircompressormake)
    formData.append("aircompressormodel", data.aircompressormodel)
    // formData.append("aircompressorcapacity", data.aircompressorcapacity)
    // formData.append("aircompressorhorsepower", data.aircompressorhorsepower)
    // formData.append("aircompressorpowersupply", data.aircompressorpowersupply)
    // formData.append("aircompressortank", data.aircompressortank)
    formData.append("maintenancedevice", data.maintenancedevice)
    formData.append("maintenancedevicemake", data.maintenancedevicemake)
    formData.append("maintenancedevicemodel", data.maintenancedevicemodel)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/adddeluge', formData);
    // console.log('response after api hit ->', response.data)
    const { deluge, error, message } = response.data;
    localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'COMPANYDELUGE',
      payload: {
        deluge,
        message,
        error
      },
    });
  };
  const updatedelugeregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("typeofpipe", data.typeofpipe)
    // formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("hydraulicallydesigned", data.hydraulicallydesigned)
    formData.append("nextdate", data.nextdate)
    formData.append("aircompressorid", data.aircompressorid)
    formData.append("aircompressormake", data.aircompressormake)
    formData.append("aircompressormodel", data.aircompressormodel)
    // formData.append("aircompressorcapacity", data.aircompressorcapacity)
    // formData.append("aircompressorhorsepower", data.aircompressorhorsepower)
    // formData.append("aircompressorpowersupply", data.aircompressorpowersupply)
    // formData.append("aircompressortank", data.aircompressortank)
    formData.append("maintenancedevice", data.maintenancedevice)
    formData.append("maintenancedevicemake", data.maintenancedevicemake)
    formData.append("maintenancedevicemodel", data.maintenancedevicemodel)
    formData.append("sprinklerheads", data.sprinklerheads)
    formData.append("guagequantity", data.guagequantity)
    formData.append("drainquantity", data.drainquantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatedeluge/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { deluge, error, message } = response.data;
    localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'COMPANYDELUGE',
      payload: {
        deluge,
        message,
        error
      },
    });
  };
  const companypullstations = async ({customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
  const response = await axios.post('api/customer/addfirealarmpullstation',  formData);
  const { pullstation, error, message } = response.data;
  // console.log('response after api hit ->',)
  localStorage.setItem('pullstationID', pullstation.id);
  dispatch({
    type: 'COMPANYPULLSTATION',
    payload: {
      pullstation,
      message,
      error
    },
  });
};


  const companyupdatepullstations = async (id, customerid, locationid, firealarmid, photo, make, model, areacovered, dateinstalled, notes) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", areacovered)
    formData.append("date_installed", dateinstalled)
    formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmpullstation/${id}`, formData);
    const { pullstation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('pullstationID', pullstation.id);
    dispatch({
      type: 'COMPANYPULLSTATION',
      payload: {
        pullstation,
        message,
        error
      },
    });
  };
  // Turabi Isolation VALVE CODE

  const delugeisolationadd = async (customerid,delugeid,locationid, type, size, takeout, valvephoto) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("delugeid", delugeid)
    formData.append("locationid", locationid)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    formData.append("valvephoto", valvephoto)
    const response = await axios.post('api/customer/adddelugeisolationvalve', formData);

    const { delugeisolation, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELUGEISOLATIONFORM',
      payload: {
        delugeisolation,
        message,
        error
      },
    });
  };
  const delugeisolationupdate = async (id, customerid, type, size, takeout, valvephoto) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    formData.append("valvephoto", valvephoto)
    const response = await axios.post(`api/customer/updatedelugeisolationvalve/${id}`, formData);

    const { delugeisolation, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELUGEISOLATIONFORM',
      payload: {
        delugeisolation,
        message,
        error
      },
    });
  };
  const deleugehydraulicadd = async (customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('pipeschedule', pipeschedule)
    formData.append('location', location)
    formData.append('discharge', discharge)
    formData.append('density', density)
    formData.append('flowpressure', flowpressure)
    formData.append('residualpressure', residualpressure)
    formData.append('occupancyclassification', occupancyclassification)
    formData.append('maxstorageheight', maxstorageheight)
    formData.append('hosestreamallowance', hosestreamallowance)

    const response = await axios.post('api/customer/adddelugehydraulicnameplate', formData);

    const { delugenameplate, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHYDRAULICADD',
      payload: {
        delugenameplate,
        message,
        error
      },
    });
  }

  const deleugehydraulicupdate = async (id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('pipeschedule', pipeschedule)
    formData.append('location', location)
    formData.append('discharge', discharge)
    formData.append('density', density)
    formData.append('flowpressure', flowpressure)
    formData.append('residualpressure', residualpressure)
    formData.append('occupancyclassification', occupancyclassification)
    formData.append('maxstorageheight', maxstorageheight)
    formData.append('hosestreamallowance', hosestreamallowance)

    const response = await axios.post(`api/customer/updatedelugehydraulicnameplate/${id}`, formData);

    const { delugenameplate, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHYDRAULICADD',
      payload: {
        delugenameplate,
        message,
        error
      },
    });
  }
  const delugeheadadd = async (
    customerid,
    delugeid,
    locationid,
    type,
    location,
    temperature,
    triggertype,
    installdate,
    servicedate,
    nextservicedate) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append(' delugeid',  delugeid)
    formData.append(' locationid',  locationid)
    formData.append('type', type)
    formData.append('location', location)
    formData.append('temperature', temperature)
    formData.append('triggertype', triggertype)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post('api/customer/adddelugehead', formData);

    const { delugehead, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHEAD',
      payload: {
        delugehead,
        message,
        error
      },
    });
  }
  const delugeheadupdate = async (
    id,
    customerid,
    type,
    location,
    temperature,
    triggertype,
    installdate,
    servicedate,
    nextservicedate) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('location', location)
    formData.append('temperature', temperature)
    formData.append('triggertype', triggertype)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post(`api/customer/updatedelugehead/${id}`, formData);

    const { delugehead, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHEAD',
      payload: {
        delugehead,
        message,
        error
      },
    });
  }
  const delugeguageadd = async (
    customerid,
    delugeid,
    locationid,
    guageid,
    location,
    type,
    installdate,
    servicedate,
    nextservicedate) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('delugeid', delugeid)
    formData.append('locationid', locationid)
    formData.append('guageid', guageid)
    formData.append('location', location)
    formData.append('type', type)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post('api/customer/adddelugeguage', formData);

    const { delugeguage, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEGUAGE',
      payload: {
        delugeguage,
        message,
        error
      },
    });
  }
  const delugeguageupdate = async (
    id,
    customerid,
    guageid,
    location,
    type,
    installdate,
    servicedate,
    nextservicedate,
  ) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('guageid', guageid)
    formData.append('location', location)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post(`api/customer/updatedelugeguage/${id}`, formData);

    const { delugeguage, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEGUAGE',
      payload: {
        delugeguage,
        message,
        error
      },
    });
  }
  const delugedrainadd = async (
    customerid,
    delugeid,
    locationid,
    type,
    size,
    location,
    connection,
    notes

  ) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('delugeid', delugeid)
    formData.append('locationid', locationid)
    formData.append('type', type)
    formData.append('size', size)
    formData.append('location', location)
    formData.append('connection', connection)
    formData.append('notes', notes)

    const response = await axios.post('api/customer/adddelugedrain', formData);

    const { delugedrain, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEDRAIN',
      payload: {
        delugedrain,
        message,
        error
      },
    });
  }
  const delugedrainupdate = async (
    id,
    customerid,
    type,
    size,
    location,
    connection,
    notes

  ) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('size', size)
    formData.append('location', location)
    formData.append('connection', connection)
    formData.append('notes', notes)

    const response = await axios.post(`api/customer/updatedelugedrain/${id}`, formData);

    const { delugedrain, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEDRAIN',
      payload: {
        delugedrain,
        message,
        error
      },
    });
  }
  const standpiperegister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("standpipeclass", data.standpipeclass)
    formData.append("standpipetype", data.standpipetype)
    formData.append("standpipefloor", data.standpipefloor)
    formData.append("standpipestairwells", data.standpipestairwells)
    formData.append("guagequantity", data.guagequantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", servicedate)
    // formData.append("nextdervicedate", nextdervicedate)

    const response = await axios.post('api/customer/addstandpipe', formData);
    // console.log('response after api hit ->', response.data)
    const { standpipe, error, message } = response.data;
    localStorage.setItem('standpipeID', standpipe.id);
    dispatch({
      type: 'COMPANYSTANDPIPE',
      payload: {
        standpipe,
        message,
        error
      },
    });
  };
  const updatestandpiperegister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    // formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("standpipeclass", data.standpipeclass)
    formData.append("standpipetype", data.standpipetype)
    formData.append("standpipefloor", data.standpipefloor)
    formData.append("standpipestairwells", data.standpipestairwells)
    formData.append("guagequantity", data.guagequantity)
    formData.append("isolationvalvequantity", data.isolationvalvequantity)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    // formData.append("servicedate", servicedate)
    // formData.append("nextdervicedate", nextdervicedate)

    const response = await axios.post(`api/customer/updatestandpipe/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { standpipe, error, message } = response.data;
    localStorage.setItem('standpipeID', standpipe.id);
    dispatch({
      type: 'COMPANYSTANDPIPE',
      payload: {
        standpipe,
        message,
        error
      },
    });
  };
  const firehydrantregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("spotphoto", data.spotphoto)
    formData.append("assetnumber", data.assetnumber)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetborndate", data.assetborndate)
    formData.append("freeandclear", data.freeandclear)
    formData.append("assetcrash", data.assetcrash)
    formData.append("assetnotes", data.assetnotes)

    const response = await axios.post('api/customer/addfirehydrant', formData);
    // console.log('response after api hit ->', response.data)
    const { firehydrant, error, message } = response.data;
    localStorage.setItem('firehydrantID', firehydrant.id);
    dispatch({
      type: 'COMPANYFIREHYDRANT',
      payload: {
        firehydrant,
        message,
        error
      },
    });
  };
  const updatefirehydrantregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("spotphoto", data.spotphoto)
    formData.append("assetnumber", data.assetnumber)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetborndate", data.assetborndate)
    formData.append("freeandclear", data.freeandclear)
    formData.append("assetcrash", data.assetcrash)
    formData.append("assetnotes", data.assetnotes)
    const response = await axios.post(`api/customer/updatefirehydrant/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { firehydrant, error, message } = response.data;
    localStorage.setItem('firehydrantID', firehydrant.id);
    dispatch({
      type: 'COMPANYFIREHYDRANT',
      payload: {
        firehydrant,
        message,
        error
      },
    });
  };
  const kitchenhoodregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("ul300", data.ul300)
    formData.append("cylindersprovideprotection", data.cylindersprovideprotection)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addkitchenhood', formData);
    // console.log('response after api hit ->', response.data)
    const { kitchenhood, error, message } = response.data;
    localStorage.setItem('kitchenhoodID', kitchenhood.id);
    dispatch({
      type: 'COMPANYKITCHENHOOD',
      payload: {
        kitchenhood,
        message,
        error
      },
    });
  };
  const updatekitchenhoodregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("ul300", data.ul300)
    formData.append("cylindersprovideprotection", data.cylindersprovideprotection)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatekitchenhood/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { kitchenhood, error, message } = response.data;
    localStorage.setItem('kitchenhoodID', kitchenhood.id);
    dispatch({
      type: 'COMPANYKITCHENHOOD',
      payload: {
        kitchenhood,
        message,
        error
      },
    });
  };
  const drychemicalregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("cylindersprovideprotection", data.cylindersprovideprotection)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/adddrychemical', formData);
    // console.log('response after api hit ->', response.data)
    const { drychemical, error, message } = response.data;
    localStorage.setItem('drychemicalID', drychemical.id);
    dispatch({
      type: 'COMPANYDRYCHEMICAL',
      payload: {
        drychemical,
        message,
        error
      },
    });
  };
  const updatedrychemicalregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("cylindersprovideprotection", data.cylindersprovideprotection)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatedrychemical/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { drychemical, error, message } = response.data;
    localStorage.setItem('drychemicalID', drychemical.id);
    dispatch({
      type: 'COMPANYDRYCHEMICAL',
      payload: {
        drychemical,
        message,
        error
      },
    });
  };
  const fireescaperegister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("escapeprovidecoverage", data.escapeprovidecoverage)
    formData.append("standpipestairwells", data.standpipestairwells)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addfireescape ', formData);
    // console.log('response after api hit ->', response.data)
    const { fireescape, error, message } = response.data;
    localStorage.setItem('fireescapeID', fireescape.id);
    dispatch({
      type: 'COMPANYFIREESCAPE',
      payload: {
        fireescape,
        message,
        error
      },
    });
  };
  const updatefireescaperegister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("escapeprovidecoverage", data.escapeprovidecoverage)
    formData.append("standpipestairwells", data.standpipestairwells)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatefireescape/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { fireescape, error, message } = response.data;
    localStorage.setItem('fireescapeID', fireescape.id);
    dispatch({
      type: 'COMPANYFIREESCAPE',
      payload: {
        fireescape,
        message,
        error
      },
    });
  };
  const cleanagentregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("cyindersprovideprotection", data.cyindersprovideprotection)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addcleanagent', formData);
    // console.log('response after api hit ->', response.data)
    const { cleanagent, error, message } = response.data;
    localStorage.setItem('cleanagentID', cleanagent.id);
    dispatch({
      type: 'COMPANYCLEANAGENT',
      payload: {
        cleanagent,
        message,
        error
      },
    });
  };
  const updatecleanagentregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("cyindersprovideprotection", data.cyindersprovideprotection)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updatecleanagent/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { cleanagent, error, message } = response.data;
    localStorage.setItem('cleanagentID', cleanagent.id);
    dispatch({
      type: 'COMPANYCLEANAGENT',
      payload: {
        cleanagent,
        message,
        error
      },
    });
  };
  const otherlocationregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("backflowtype", data.backflowtype)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post('api/customer/addother', formData);
    // console.log('response after api hit ->', response.data)
    const { otherlocation, error, message } = response.data;
    localStorage.setItem('otherlocationID', otherlocation.id);
    dispatch({
      type: 'COMPANYOTHERLOCATION',
      payload: {
        otherlocation,
        message,
        error
      },
    });
  };


 const updateotherlocationregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("assetid", data.assetid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("placardphoto", data.placardphoto)
    formData.append("assetname", data.assetname)
    formData.append("assetlocation", data.assetlocation)
    formData.append("assetprotect", data.assetprotect)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserialnumber", data.assetserialnumber)
    formData.append("assetborndate", data.assetborndate)
    formData.append("backflowtype", data.backflowtype)
    formData.append("installingcontractor", data.installingcontractor)
    formData.append("distributorcompany", data.distributorcompany)
    formData.append("servicecontractor", data.servicecontractor)
    formData.append("assetahj", data.assetahj)
    formData.append("assetcondition", data.assetcondition)
    formData.append("replacementavailable", data.replacementavailable)
    formData.append("budgetqouterequest", data.budgetqouterequest)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    formData.append("servicedate", data.servicedate)
    formData.append("nextservicedate", data.nextservicedate)

    const response = await axios.post(`api/customer/updateother/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { otherlocation, error, message } = response.data;
    localStorage.setItem('otherlocationID', otherlocation.id);
    dispatch({
      type: 'COMPANYOTHERLOCATION',
      payload: {
        otherlocation,
        message,
        error
      },
    });
  };


  const companybatterydetail = async ({customerid, onsiteid, location, type, voltage, amperage, installdate, servicedate, nextservicedate, notes,locationid,firealarmid}) => {
    const response = await axios.post('api/customer/addfirealarmbatterydetail', {
      locationid,
      firealarmid,
      customerid,
      onsiteid,
      location,
      type,
      voltage,
      amperage,
      installdate,
      servicedate,
      nextservicedate,
      notes
    });
    const { batterydetail, error, message } = response.data;
    // 
    localStorage.setItem('batterydetailID', batterydetail.id);
    dispatch({
      type: 'COMPANYBATTERYDETAIL',
      payload: {
        batterydetail,
        message,
        error
      },
    });
  };

  const companyupdatebatterydetail = async ({id, customerid, onsiteid, location, type, voltage, amperage, installdate, servicedate, nextservicedate, notes ,locationid,firealarmid}) => {
    const response = await axios.post(`api/customer/updatefirealarmbatterydetail/${id}`, {
      customerid,
      onsiteid,
      location,
      type,
      voltage,
      amperage,
      installdate,
      servicedate,
      nextservicedate,
      notes,
      locationid,
      firealarmid,
    });
    const { batterydetail, error, message } = response.data;
    // 
    dispatch({
      type: 'COMPANYBATTERYDETAIL',
      payload: {
        batterydetail,
        message,
        error
      },
    });
  };
 
  const companyductdetector = async ({customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {
 
 
       const formData = new FormData();
       formData.append("customerid", customerid)
       formData.append("locationid", locationid)
       formData.append("firealarmid", firealarmid)
       formData.append("photo", photo)
       formData.append("make", make)
       formData.append("model", model)
       formData.append("area_covered", area_covered)
       formData.append("date_installed", date_installed)
       formData.append("notes", notes)
     const response = await axios.post('api/customer/addfirealarmductdetectordetail',  formData);
     const { ductdetector, error, message } = response.data;
     // console.log('response after api hit ->',)
     localStorage.setItem('ductdetectorID', ductdetector.id);
     dispatch({
       type: 'COMPANYDUCTDETECTOR',
       payload: {
         ductdetector,
         message,
         error
       },
     });
   };
 
 
   const companyupdateductdetector = async ({id,customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {
 
 
       const formData = new FormData();
       formData.append("customerid", customerid)
       formData.append("locationid", locationid)
       formData.append("firealarmid", firealarmid)
       formData.append("photo", photo)
       formData.append("make", make)
       formData.append("model", model)
       formData.append("area_covered", area_covered)
       formData.append("date_installed", date_installed)
       formData.append("notes", notes)
     const response = await axios.post(`api/customer/updatefirealarmductdetectordetail/${id}`,  formData);
     const { ductdetector, error, message } = response.data;
     // console.log('response after api hit ->',)
     localStorage.setItem('ductdetectorID', ductdetector.id);
     dispatch({
       type: 'COMPANYDUCTDETECTOR',
       payload: {
         ductdetector,
         message,
         error
       },
     });
   };
   const companyheatdetector = async ({customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,rating,notes}) => {

    console.log(customerid)
  
      const formData = new FormData();
      formData.append("customerid", customerid)
      formData.append("locationid", locationid)
      formData.append("firealarmid", firealarmid)
      formData.append("photo", photo)
      formData.append("make", make)
      formData.append("model", model)
      formData.append("area_covered", area_covered)
      formData.append("temperature_rating", rating)
      formData.append("date_installed", date_installed)
      formData.append("notes", notes)
      
    const response = await axios.post('api/customer/addfirealarmheatdetectordetail', formData);
    const { heatdetector, error, message } = response.data;
    console.log('response after api hit ->',)
    localStorage.setItem('heatdetectorID', heatdetector.id);
    dispatch({
      type: 'COMPANYHEATDETECTOR',
      payload: {
        heatdetector,
        message,
        error
      },
    });
  };
  
  
  const companyupdateheatdetector = async ({id,customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,rating,notes}) => {
  
  
      const formData = new FormData();
      formData.append("customerid", customerid)
      formData.append("locationid", locationid)
      formData.append("firealarmid", firealarmid)
      formData.append("photo", photo)
      formData.append("make", make)
      formData.append("model", model)
      formData.append("area_covered", area_covered)
      formData.append("temperature_rating", rating)
      formData.append("date_installed", date_installed)
      formData.append("notes", notes)
      
    const response = await axios.post(`api/customer/updatefirealarmheatdetectordetail/${id}`, formData);
    const { heatdetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('heatdetectorID', heatdetector.id);
    dispatch({
      type: 'COMPANYHEATDETECTOR',
      payload: {
        heatdetector,
        message,
        error
      },
    });
  };
  const companysupervisory = async ({customerid, locationid, firealarmid,model,type ,photo,make ,system_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("system_covered", system_covered)
    formData.append("type", type)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmsupervisoryswitch',formData);
    const { supervisory, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('supervisoryID', supervisory.id);
    dispatch({
      type: 'COMPANYSUPERVISORY',
      payload: {
        supervisory,
        message,
        error
      },
    });
  };


  const companyupdatesupervisory = async ({id,customerid, locationid, firealarmid,model,type ,photo,make ,system_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("system_covered", system_covered)
    formData.append("type", type)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmsupervisoryswitch/${id}`,formData);
    const { supervisory, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('supervisoryID', supervisory.id);
    dispatch({
      type: 'COMPANYSUPERVISORY',
      payload: {
        supervisory,
        message,
        error
      },
    });
  };
  const companybelldetector = async ({customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmbell',formData);
    const { bell, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('bellID', bell.id);
    dispatch({
      type: 'COMPANYBELL',
      payload: {
        bell,
        message,
        error
      },
    });
  };
  // eslint-disable-next-line camelcase
  const companyupdatebelldetector = async ({id,customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmbell/${id}`,formData);
    const { bell, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('bellID', bell.id);
    dispatch({
      type: 'COMPANYBELL',
      payload: {
        bell,
        message,
        error
      },
    });
  };
  const companyflowswitch = async ({customerid, locationid, firealarmid,model,type ,photo,make , size,system_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("system_covered", system_covered)
    formData.append("type", type)
    formData.append("size", size)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmflowswitch',formData);
    const { flowswitch, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('flowswitchID', flowswitch.id);
    dispatch({
      type: 'COMPANYFLOWSWITCH',
      payload: {
        flowswitch,
        message,
        error
      },
    });
  };


  const companyupdateflowswitch =async ({id,customerid, locationid, firealarmid,model,type ,photo,make , size,system_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("system_covered", system_covered)
    formData.append("type", type)
    formData.append("size", size)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmflowswitch/${id}`, formData);
    const { flowswitch, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('flowswitchID', flowswitch.id);
    dispatch({
      type: 'COMPANYFLOWSWITCH',
      payload: {
        flowswitch,
        message,
        error
      },
    });
  };
  const companywetantifreeze = async ({customerid, solutiontype, gallonsquantity,locationid,wetsprinklerid, lastdrainmix, ullisted}) => {
    const response = await axios.post('api/customer/addwetantifreezesprinkler', {
      customerid,
      locationid,
      wetsprinklerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { wetantifreeze, error, message } = response.data;
    // 
    localStorage.setItem('wetantifreezeID', wetantifreeze.id);
    dispatch({
      type: 'COMPANYWETANTIFREEZE',
      payload: {
        wetantifreeze,
        message,
        error
      },
    });
  };
  const companyupdatewetantifreeze = async ({id, customerid, solutiontype, gallonsquantity,locationid,wetsprinklerid, lastdrainmix, ullisted}) => {
    const response = await axios.post(`api/customer/updatewetantifreezesprinkler/${id}`, {
      customerid,
      locationid,
      wetsprinklerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { wetantifreeze, error, message } = response.data;
    // 
    localStorage.setItem('wetantifreezeID', wetantifreeze.id);
    dispatch({
      type: 'COMPANYWETANTIFREEZE',
      payload: {
        wetantifreeze,
        message,
        error
      },
    });
  };
  const companywetguage = async ({customerid, guageid, location, type, installdate,locationid,wetsprinklerid, servicedate, nextservicedate}) => {
    const response = await axios.post('api/customer/addwetsprinklerguage', {
      customerid,
      locationid,
      wetsprinklerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { wetguage, error, message } = response.data;
    // 
    localStorage.setItem('wetguageID', wetguage.id);
    dispatch({
      type: 'COMPANYWETGUAGE',
      payload: {
        wetguage,
        message,
        error
      },
    });
  };
  const companyupdatewetguage = async ({id, customerid, guageid, location, type, installdate, servicedate,locationid,wetsprinklerid, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatewetsprinklerguage/${id}`, {
      customerid,
      locationid,
      wetsprinklerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { wetguage, error, message } = response.data;
    // 
    localStorage.setItem('wetguageID', wetguage.id);
    dispatch({
      type: 'COMPANYWETGUAGE',
      payload: {
        wetguage,
        message,
        error
      },
    });
  };
  const companywetnameplate = async ({customerid,wetsprinklerid, locationid, pipeschedule ,location, discharge,density, flowpressure, residualpressure, occupancyclassification, maximumheight, hosestream}) => {
    const response = await axios.post('api/customer/addwetsprinklerhydraulicnameplate', {
      customerid,
      wetsprinklerid,
      locationid,
      location,
      pipeschedule,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maximumheight,
      hosestream
    });
    const { wetnameplate, error, message } = response.data;
    // 
    localStorage.setItem('wetnameplateID', wetnameplate.id);
    dispatch({
      type: 'COMPANYNWETAMEPLATE',
      payload: { 
        wetnameplate,
        message,
        error
      },
    });
  };
  const companyupdatewetnameplate = async ({id, customerid, wetsprinklerid,pipeschedule, locationid, discharge,location, density, flowpressure, residualpressure, occupancyclassification, maximumheight, hosestream}) => {
    const response = await axios.post(`api/customer/updatewetsprinklerhydraulicnameplate/${id}`, {
      customerid,
      wetsprinklerid,
      locationid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maximumheight,
      hosestream
    });
    const { wetnameplate, error, message } = response.data;
    // 
    localStorage.setItem('wetnameplateID', wetnameplate.id);
    dispatch({
      type: 'COMPANYNWETAMEPLATE',
      payload: {
        wetnameplate,
        message,
        error
      },
    });
  };
  const companywetisolation = async ({customerid, valvephoto, type, size, takeout,wetsprinklerid,locationid}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("wetsprinklerid", wetsprinklerid)
    formData.append("locationid", locationid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addwetsprinklerisolationvalve', formData);
    const { wetisolation, error, message } = response.data;
    // 
    localStorage.setItem('wetisolationID', wetisolation.id);
    dispatch({
      type: 'COMPANYWETISOLATION',
      payload: {
        wetisolation,
        message,
        error
      },
    });
  };
  const companyupdatewetisolation = async ({id, customerid, valvephoto, type, size, takeout,wetsprinklerid,locationid}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("wetsprinklerid", wetsprinklerid)
    formData.append("locationid", locationid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatewetsprinklerisolationvalve/${id}`, formData);
    const { wetisolation, error, message } = response.data;
    // 
    localStorage.setItem('wetisolationID', wetisolation.id);
    dispatch({
      type: 'COMPANYWETISOLATION',
      payload: {
        wetisolation,
        message,
        error
      },
    });
  };
  const companywetdrain = async ({customerid, type,locationid,wetsprinklerid, size, location, details, notes}) => {
    const response = await axios.post('api/customer/addwetsprinklerdrain', {
      customerid,
      locationid,
      wetsprinklerid,
      type,
      size,
      location,
      details,
      notes
    });
    const { wetdrain, error, message } = response.data;
    // 
    localStorage.setItem('wetdrainID', wetdrain.id);
    dispatch({
      type: 'COMPANYWETDRAIN',
      payload: {
        wetdrain,
        message,
        error
      },
    });
  };
  const companyupdatewetdrain = async ({id, customerid, type,locationid, size,wetsprinklerid, location, details, notes}) => {
    const response = await axios.post(`api/customer/updatewetsprinklerdrain/${id}`, {
      customerid,
      locationid,
      wetsprinklerid,
      type,
      size,
      location,
      details,
      notes
    });
    const { wetdrain, error, message } = response.data;
    // 
    localStorage.setItem('wetdrainID', wetdrain.id);
    dispatch({
      type: 'COMPANYWETDRAIN',
      payload: {
        wetdrain,
        message,
        error
      },
    });
  };
  const companywethead = async ({ customerid, installdate, locationid,wetsprinklerid, type, location, nextservicedate, servicedate, temperature, triggertype }) => {
    const response = await axios.post('api/customer/addwetsprinklerhead', {
      customerid,
      locationid,
      wetsprinklerid,
      type,
      installdate,
      location,
      nextservicedate,
      servicedate,
      temperature,
      triggertype
    });
    const { wethead, error, message } = response.data;
    // 
    localStorage.setItem('wetheadID', wethead.id);
    dispatch({
      type: 'COMPANYWETHEAD',
      payload: {
        wethead,
        message,
        error
      },
    });
  };
  const companyupdatewethead = async ({ id, customerid, installdate, type, location,locationid,wetsprinklerid, nextservicedate, servicedate, temperature, triggertype }) => {
    const response = await axios.post(`api/customer/updatewetsprinklerhead/${id}`, {
      customerid,
      locationid,
      wetsprinklerid,
      type,
      installdate,
      location,
      nextservicedate,
      servicedate,
      temperature,
      triggertype
    });
    const { wethead, error, message } = response.data;
    // 
    localStorage.setItem('wetheadID', wethead.id);
    dispatch({
      type: 'COMPANYWETHEAD',
      payload: {
        wethead,
        message,
        error
      },
    });
  };
  const companydryantifreeze = async (customerid, solutiontype, gallonsquantity, lastdrainmix, ullisted) => {
    const response = await axios.post('api/customer/adddryantifreezesprinkler', {
      customerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { dryantifreeze, error, message } = response.data;
    // 
    localStorage.setItem('dryantifreezeID', dryantifreeze.id);
    dispatch({
      type: 'COMPANYDRYANTIFREEZE',
      payload: {
        dryantifreeze,
        message,
        error
      },
    });
  };
  const companydryguage = async (customerid,drysprinklerid,locationid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/adddrysprinklerguage', {
      customerid,
      drysprinklerid,
      locationid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryguage, error, message } = response.data;
    // 
    localStorage.setItem('dryguageID', dryguage.id);
    dispatch({
      type: 'COMPANYDRYGUAGE',
      payload: {
        dryguage,
        message,
        error
      },
    });
  };
  const companyupdatedryguage = async ({id, customerid,drysprinklerid, guageid, location, type, installdate, servicedate, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatedrysprinklerguage/${id}`, {
      customerid,
      guageid,
      drysprinklerid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryguage, error, message } = response.data;
    // 
    localStorage.setItem('dryguageID', dryguage.id);
    dispatch({
      type: 'COMPANYDRYGUAGE',
      payload: {
        dryguage,
        message,
        error
      },
    });
  };
  const companydrynameplate = async (customerid,drysprinklerid,locationid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const response = await axios.post('api/customer/adddrysprinklerhydraulicnameplate', {
      customerid,
      drysprinklerid,
      locationid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { drynameplate, error, message } = response.data;
    // 
    localStorage.setItem('drynameplateID', drynameplate.id);
    dispatch({
      type: 'COMPANYDRYNAMEPLATE',
      payload: {
        drynameplate,
        message,
        error
      },
    });
  };
  const companyupdatedrynameplate = async (id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const response = await axios.post(`api/customer/updatedrysprinklerhydraulicnameplate/${id}`, {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { drynameplate, error, message } = response.data;
    // 
    localStorage.setItem('drynameplateID', drynameplate.id);
    dispatch({
      type: 'COMPANYDRYNAMEPLATE',
      payload: {
        drynameplate,
        message,
        error
      },
    });
  };
  const companydryisolation = async (customerid,drySprinklerid,locationid, valvephoto, type, size, takeout) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("drysprinklerid", drySprinklerid)
    formData.append("locationid",locationid )
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/adddrysprinklerisolationvalve', formData);
    const { dryisolation, error, message } = response.data;
    // 
    localStorage.setItem('dryisolationID', dryisolation.id);
    dispatch({
      type: 'COMPANYDRYISOLATION',
      payload: {
        dryisolation,
        message,
        error
      },
    });
  };

  const companyupdatedryisolation = async (id, customerid, valvephoto, type, size, takeout) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatedrysprinklerisolationvalve/${id}`, formData);
    const { dryisolation, error, message } = response.data;
    // 
    localStorage.setItem('dryisolationID', dryisolation.id);
    dispatch({
      type: 'COMPANYDRYISOLATION',
      payload: {
        dryisolation,
        message,
        error
      },
    });
  };
  const companydrydrain = async ({customerid,drysprinklerid ,locationid,locationId, type, size, location, connection, notes}) => {
    const response = await axios.post('api/customer/adddrysprinklerdrain', {
      customerid,
      drysprinklerid,
      locationid,
      type,
      locationId,
      size,
      location,
      connection,
      notes
    });
    const { drydrain, error, message } = response.data;
    // 
    localStorage.setItem('drydrainID', drydrain.id);
    dispatch({
      type: 'COMPANYDRYDRAIN',
      payload: {
        drydrain,
        message,
        error
      },
    });
  };
  const companyupdatedrydrain = async ({id, customerid,drysprinklerid, type, size, location,locationId, connection, notes}) => {
    console.log(id,"ahmed")
    const response = await axios.post(`api/customer/updatedrysprinklerdrain/${id}`, {
      customerid,
      drysprinklerid,
      locationId,
      type,
      size,
      location,
      connection,
      notes
    });
    const { drydrain, error, message } = response.data;
    // 
    localStorage.setItem('drydrainID', drydrain.id);
    dispatch({
      type: 'COMPANYDRYDRAIN',
      payload: {
        drydrain,
        message,
        error
      },
    });
  };
  const companydryhead = async (customerid,drysprinklerid,locationid,type, location, temperature, triggertype, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/adddrysprinklerhead', {
      customerid,
      drysprinklerid,
      locationid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryhead, error, message } = response.data;
    // 
    localStorage.setItem('dryheadID', dryhead.id);
    dispatch({
      type: 'COMPANYDRYHEAD',
      payload: {
        dryhead,
        message,
        error
      },
    });
  };
  const companyupdatedryhead = async ({id, customerid, type, location, temperature, triggertype, installdate, servicedate, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatedrysprinklerhead/${id}`, {
      customerid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryhead, error, message } = response.data;
    // 
    localStorage.setItem('dryheadID', dryhead.id);
    dispatch({
      type: 'COMPANYDRYHEAD',
      payload: {
        dryhead,
        message,
        error
      },
    });
  };
  const companypreantifreeze = async (customerid, solutiontype, gallonsquantity, lastdrainmix, ullisted) => {
    const response = await axios.post('api/customer/addpreantifreezesprinkler', {
      customerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { preantifreeze, error, message } = response.data;
    // 
    localStorage.setItem('preantifreezeID', preantifreeze.id);
    dispatch({
      type: 'COMPANYPREANTIFREEZE',
      payload: {
        preantifreeze,
        message,
        error
      },
    });
  };
  const companypreguage = async ({customerid, guageid, location, type, installdate, servicedate, nextservicedate,locationid,preactionid}) => {
    const response = await axios.post('api/customer/addpreactionguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      preactionid,
      locationid,
      nextservicedate
    });
    const { preguage, error, message } = response.data;
    // 
    localStorage.setItem('preguageID', preguage.id);
    dispatch({
      type: 'COMPANYPREGUAGE',
      payload: {
        preguage,
        message,
        error
      },
    });
  };

  const companyupdatepreguage = async ({id, customerid, guageid, location, type, installdate, servicedate, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatepreactionguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { preguage, error, message } = response.data;
    // 
    localStorage.setItem('preguageID', preguage.id);
    dispatch({
      type: 'COMPANYPREGUAGE',
      payload: {
        preguage,
        message,
        error
      },
    });
  };
  const companyprenameplate = async ({customerid, preactionid, locationid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance}) => {
    const response = await axios.post('api/customer/addpreactionhydraulicnameplate', {
      customerid,
      preactionid,
      locationid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    console.log(response.data)
    const { prenameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('prenameplateID', prenameplate.id);
    dispatch({
      type: 'COMPANYNPREAMEPLATE',
      payload: {
        prenameplate,
        message,
        error
      },
    });
  };


  const companyupdateprenameplate = async ({id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance}) => {
    const response = await axios.post(`api/customer/updatepreactionhydraulicnameplate/${id}`, {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { prenameplate, error, message } = response.data;
    // 
    localStorage.setItem('prenameplateID', prenameplate.id);
    dispatch({
      type: 'COMPANYNPREAMEPLATE',
      payload: {
        prenameplate,
        message,
        error
      },
    });
  };
  const companypreisolation = async ({customerid, valvephoto, type, size, takeout,locationid,preactionid}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("preactionid", preactionid)
    formData.append("locationid", locationid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addpreactionisolationvalve', formData);
    const { preisolation, error, message } = response.data;
    // 
    localStorage.setItem('preisolationID', preisolation.id);
    dispatch({
      type: 'COMPANYPREISOLATION',
      payload: {
        preisolation,
        message,
        error
      },
    });
  };


  const companyupdatepreisolation = async ({id, customerid, valvephoto, type, size, takeout}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatepreactionisolationvalve/${id}`, formData);
    const { preisolation, error, message } = response.data;
    // 
    localStorage.setItem('preisolationID', preisolation.id);
    dispatch({
      type: 'COMPANYPREISOLATION',
      payload: {
        preisolation,
        message,
        error
      },
    });
  };
  const companypredrain = async ({customerid, type, size, location, connection, notes, preactionid,locationid}) => {
    const response = await axios.post('api/customer/addpreactiondrain', {
      customerid,
      type,
      size,
      location,
      preactionid,
      locationid,
      connection,
      notes
    });
    const { predrain, error, message } = response.data;
    // 
    localStorage.setItem('predrainID', predrain.id);
    dispatch({
      type: 'COMPANYPREDRAIN',
      payload: {
        predrain,
        message,
        error
      },
    });
  };

  const companyupdatepredrain = async ({id, customerid, type, size, location, connection, notes}) => {
    const response = await axios.post(`api/customer/updatepreactiondrain/${id}`, {
      customerid,
      type,
      size,
      location,
      connection,
      notes
    });
    const { predrain, error, message } = response.data;
    // 
    localStorage.setItem('predrainID', predrain.id);
    dispatch({
      type: 'COMPANYPREDRAIN',
      payload: {
        predrain,
        message,
        error
      },
    });
  };
  const companyprehead = async ({customerid, type, locationid, temperature, triggertype, installdate, servicedate, nextservicedate,preactionid}) => {
    const response = await axios.post('api/customer/addpreactionhead', {
      customerid,
      type,
      locationid,
      temperature,
      triggertype,
      installdate,
      nextservicedate,
      servicedate,
      preactionid
    });
    const { prehead, error, message } = response.data;
    // 
    localStorage.setItem('preheadID', prehead.id);
    dispatch({
      type: 'COMPANYPREHEAD',
      payload: {
        prehead,
        message,
        error
      },
    });
  };

  const companyupdateprehead = async ({id, customerid, type, location, temperature, triggertype, installdate, servicedate, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatepreactionhead/${id}`, {
      customerid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      nextservicedate,
      servicedate,
    });
    const { prehead, error, message } = response.data;
    // 
    localStorage.setItem('preheadID', prehead.id);
    dispatch({
      type: 'COMPANYPREHEAD',
      payload: {
        prehead,
        message,
        error
      },
    });
  };

  const companykhcylinderdetail = async (customerid,kitchenhoodid,locationid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addkitchenhoodcylinderdetail', {
      customerid,
      kitchenhoodid,
      locationid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { khcylinderdetail, error, message } = response.data;
    // 
    localStorage.setItem('khcylinderdetailID', khcylinderdetail.id);
    dispatch({
      type: 'COMPANYKHCYLINDERDETAIL',
      payload: {
        khcylinderdetail,
        message,
        error
      },
    });
  };
  const companyupdatekhcylinderdetail = async (id, customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatekitchenhoodcylinderdetail/${id}`, {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { khcylinderdetail, error, message } = response.data;
    // 
    localStorage.setItem('khcylinderdetailID', khcylinderdetail.id);
    dispatch({
      type: 'COMPANYKHCYLINDERDETAIL',
      payload: {
        khcylinderdetail,
        message,
        error
      },
    });
  };

  const companycylinderdetail = async (customerid, drychemicalid, locationid, cylinderid, location, type, make, cylinderborndate, installdate,servicedate,nextservicedate) => {
    const response = await axios.post('api/customer/adddrychemicalcylinderdetail', {
      customerid,
      drychemicalid,
      locationid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cylinderdetail, error, message } = response.data;
    // 
    localStorage.setItem('cylinderdetailID', cylinderdetail.id);
    dispatch({
      type: 'COMPANYCYLINDERDETAIL',
      payload: {
        cylinderdetail,
        message,
        error
      },
    });
  };
  const companyupdatecylinderdetail = async (id, customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatedrychemicalcylinderdetail/${id}`, {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cylinderdetail, error, message } = response.data;
    // 
    localStorage.setItem('cylinderdetailID', cylinderdetail.id);
    dispatch({
      type: 'COMPANYCYLINDERDETAIL',
      payload: {
        cylinderdetail,
        message,
        error
      },
    });
  };

  const companycacylinderdetail = async (customerid,cleanagentid,locationid,cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addcleanagentcylinderdetail', {
      customerid,
      cleanagentid,
      locationid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cacylinderdetail, error, message } = response.data;
    // 
    localStorage.setItem('cacylinderdetailID', cacylinderdetail.id);
    dispatch({
      type: 'COMPANYCACYLINDERDETAIL',
      payload: {
        cacylinderdetail,
        message,
        error
      },
    });
  };
  const companyupdatecacylinderdetail = async (id, customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatecleanagentcylinderdetail/${id}`, {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cacylinderdetail, error, message } = response.data;
    // 
    localStorage.setItem('cacylinderdetailID', cacylinderdetail.id);
    dispatch({
      type: 'COMPANYCACYLINDERDETAIL',
      payload: {
        cacylinderdetail,
        message,
        error
      },
    });
  };

  const companyfhdetail = async (customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, valvelocated, servicedate, nextservicedate) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("valvelocated", valvelocated)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post('api/customer/addfirehydrantdetail', formData);
    const { fhdetail, error, message } = response.data;
    // 
    localStorage.setItem('fhdetailID', fhdetail.id);
    dispatch({
      type: 'COMPANYFHDETAIL',
      payload: {
        fhdetail,
        message,
        error
      },
    });
  };
  const companyupdatefhdetail = async (id, customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, valvelocated, servicedate, nextservicedate) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("valvelocated", valvelocated)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post(`api/customer/updatefirehydrantdetail/${id}`, formData);
    const { fhdetail, error, message } = response.data;
    // 
    localStorage.setItem('fhdetailID', fhdetail.id);
    dispatch({
      type: 'COMPANYFHDETAIL',
      payload: {
        fhdetail,
        message,
        error
      },
    });
  };
  const companyfedetail = async (customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, servicedate, nextservicedate) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post('api/customer/addfireextingusherdetail', formData);
    const { fedetail, error, message } = response.data;
    // 
    localStorage.setItem('fedetailID', fedetail.id);
    dispatch({
      type: 'COMPANYFEDETAIL',
      payload: {
        fedetail,
        message,
        error
      },
    });
  };
  const companyupdatefedetail = async (id, customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, servicedate, nextservicedate) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post(`api/customer/updatefireextingusherdetail/${id}`, formData);
    const { fedetail, error, message } = response.data;
    // 
    localStorage.setItem('fedetailID', fedetail.id);
    dispatch({
      type: 'COMPANYFEDETAIL',
      payload: {
        fedetail,
        message,
        error
      },
    });
  };
  const companybfisolation = async (customerid, valvephoto, type, size, takeout) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addbackflowisolationvalve', formData);
    const { bfisolation, error, message } = response.data;
    // 
    localStorage.setItem('bfisolationID', bfisolation.id);
    dispatch({
      type: 'COMPANYBFISOLATION',
      payload: {
        bfisolation,
        message,
        error
      },
    });
  };
  const companyupdatebfisolation = async (id, customerid, valvephoto, type, size, takeout) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatebackflowisolationvalve/${id}`, formData);
    const { bfisolation, error, message } = response.data;
    // 
    localStorage.setItem('bfisolationID', bfisolation.id);
    dispatch({
      type: 'COMPANYBFISOLATION',
      payload: {
        bfisolation,
        message,
        error
      },
    });
  };
  const companyspguage = async ({customerid, guageid, location, type, installdate, servicedate, nextservicedate,standpipeid ,locationid}) => {
    const response = await axios.post('api/customer/addstandpipeguage', {
      customerid,
      guageid,
      location,
      standpipeid,
      locationid,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { spguage, error, message } = response.data;
    // 
    localStorage.setItem('spguageID', spguage.id);
    dispatch({
      type: 'COMPANYSTANDGUAGE',
      payload: {
        spguage,
        message,
        error
      },
    });
  };

  const companyupdatespguage = async ({id, customerid, guageid, location, type, installdate, servicedate, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatestandpipeguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { spguage, error, message } = response.data;
    // 
    localStorage.setItem('spguageID', spguage.id);
    dispatch({
      type: 'COMPANYSTANDGUAGE',
      payload: {
        spguage,
        message,
        error
      },
    });
  };
  const companyspisolation = async ({customerid, valvephoto, type, size, takeout,locationid,standPipeids}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("standpipeid", standPipeids)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addstandpipeisolationvalve', formData);
    const { spisolation, error, message } = response.data;
    // 
    localStorage.setItem('spisolationID', spisolation.id);
    dispatch({
      type: 'COMPANYSTANDISOLATION',
      payload: {
        spisolation,
        message,
        error
      },
    });
  };

  const companyupdatespisolation = async ({id, customerid, valvephoto, type, size, takeout}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatestandpipeisolationvalve/${id}`, formData);
    const { spisolation, error, message } = response.data;
    // 
    localStorage.setItem('spisolationID', spisolation.id);
    dispatch({
      type: 'COMPANYSTANDISOLATION',
      payload: {
        spisolation,
        message,
        error
      },
    });
  };
  const companyfpguage = async ({customerid, guageid, location, type, installdate, servicedate, nextservicedate, firepumpid,locationid }) => {
    const response = await axios.post('api/customer/addfirepumpguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate,
      locationid,  
      "firepumpid":firepumpid
    });
    const { fpguage, error, message } = response.data;
    // 
    localStorage.setItem('fpguageID', fpguage.id);
    dispatch({
      type: 'COMPANYFPGUAGE',
      payload: {
        fpguage,
        message,
        error
      },
    });
  };


  const companyupdatefpguage = async ({id, customerid, guageid, location, type, installdate, servicedate, nextservicedate}) => {
    const response = await axios.post(`api/customer/updatefirepumpguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { fpguage, error, message } = response.data;
    // 
    localStorage.setItem('fpguageID', fpguage.id);
    dispatch({
      type: 'COMPANYFPGUAGE',
      payload: {
        fpguage,
        message,
        error
      },
    });
  };
  const companyfpisolation = async ({customerid, firepumpid, locationid, valvephoto, type, size, takeout}) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("firepumpid", firepumpid)
    formData.append("locationid", locationid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addfirepumpisolationvalve', formData);
    const { fpisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fpisolationID', fpisolation.id);
    dispatch({
      type: 'COMPANYFPISOLATION',
      payload: {
        fpisolation,
        message,
        error
      },
    });
  };


  const companyupdatefpisolation = async ({id, customerid, valvephoto, type, size, takeout}) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatefirepumpisolationvalve/${id}`, formData);
    const { fpisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fpisolationID', fpisolation.id);
    dispatch({
      type: 'COMPANYFPISOLATION',
      payload: {
        fpisolation,
        message,
        error
      },
    });
  };
  const customercompany = async (customeId) => {
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: true
      },
    });
    const response = await axios.get(`api/customer/companies/${customeId}`);
    const { company, contact, location, service, jobentery, elevator, keylogs, firedep, firealarm, drysprinkler, wetsprinkler, firepump, preaction, deluge, standpipe, firehydrant, kitchenhood, cleanagent, drychemical, fireescape, fireextinguisher, backflow, otherlocation, batterydetail, ductdetector, heatdetector, supervisory, flowswitch, wetantifreeze, wetguage, wetnameplate, wetisolation, wetdrain, wethead, dryantifreeze, dryguage, drynameplate, dryisolation, drydrain, dryhead, delugenameplate, delugeisolation, delugehead, delugeguage, delugedrain, preantifreeze, preguage, prenameplate, preisolation, predrain, prehead, spguage, spisolation, fpguage, fpisolation, khcylinderdetail, cacylinderdetail, cylinderdetail, fhdetail, fedetail, bfisolation } = response.data;
    console.log(response.data,"data")
    
    dispatch({
      type: 'COMPANYDATA',
      payload: {
        company,
        contact,
        location,
        service,
        jobentery,
        elevator,
        keylogs,
        firedep,
        firealarm,
        drysprinkler,
        wetsprinkler,
        firepump,
        preaction,
        deluge,
        standpipe,
        firehydrant,
        kitchenhood,
        cleanagent,
        drychemical,
        fireescape,
        fireextinguisher,
        backflow,
        otherlocation,
        batterydetail,
        ductdetector,
        heatdetector,
        supervisory,
        flowswitch,
        wetguage,
        wetantifreeze,
        wetnameplate,
        wetisolation,
        wetdrain,
        wethead,
        dryantifreeze,
        dryguage,

        // Turabi

        drynameplate,
        dryisolation,
        delugehead,
        delugeguage,
        delugedrain,
        drydrain,
        dryhead,
        delugenameplate,
        delugeisolation,
        preantifreeze,
        preguage,
        prenameplate,
        preisolation,
        predrain,
        prehead,
        spguage,
        spisolation,
        fpguage,
        fpisolation,
        khcylinderdetail,
        cacylinderdetail,
        cylinderdetail,
        fhdetail,
        fedetail,
        bfisolation,
      },
    });
    // 
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: false
      },
    });
    return response;
  };
  
  const getLoactionandCustomerData = async (customeId,locationId) => {
    console.log("from my function")
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: true
      },
    });
    const response = await axios.get(`/api/customer/companieswithlocation/${customeId}/${locationId}`);
    const { company, contact, location, service, jobentery, elevator, keylogs, firedep, firealarm, drysprinkler, wetsprinkler, firepump, preaction, deluge, standpipe, firehydrant, kitchenhood, cleanagent, drychemical, fireescape, fireextinguisher, backflow, otherlocation, batterydetail, ductdetector, heatdetector, supervisory, flowswitch, wetantifreeze, wetguage, wetnameplate, wetisolation, wetdrain, wethead, dryantifreeze, dryguage, drynameplate, dryisolation, drydrain, dryhead, delugenameplate, delugeisolation, delugehead, delugeguage, delugedrain, preantifreeze, preguage, prenameplate, preisolation, predrain, prehead, spguage, spisolation, fpguage, fpisolation, khcylinderdetail, cacylinderdetail, cylinderdetail, fhdetail, fedetail, bfisolation } = response.data;
    console.log(response.data,"api called successfully")
    dispatch({
      type: 'COMPANYDATA',
      payload: {
        company,
        contact,
        location,
        service,
        jobentery,
        elevator,
        keylogs,
        firedep,
        firealarm,
        drysprinkler,
        wetsprinkler,
        firepump,
        preaction,
        deluge,
        standpipe,
        firehydrant,
        kitchenhood,
        cleanagent,
        drychemical,
        fireescape,
        fireextinguisher,
        backflow,
        otherlocation,
        batterydetail,
        ductdetector,
        heatdetector,
        supervisory,
        flowswitch,
        wetguage,
        wetantifreeze,
        wetnameplate,
        wetisolation,
        wetdrain,
        wethead,
        dryantifreeze,
        dryguage,

        // Turabi

        drynameplate,
        dryisolation,
        delugehead,
        delugeguage,
        delugedrain,
        drydrain,
        dryhead,
        delugenameplate,
        delugeisolation,
        preantifreeze,
        preguage,
        prenameplate,
        preisolation,
        predrain,
        prehead,
        spguage,
        spisolation,
        fpguage,
        fpisolation,
        khcylinderdetail,
        cacylinderdetail,
        cylinderdetail,
        fhdetail,
        fedetail,
        bfisolation,
      },
    });
    // 
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: false
      },
    });
    return response;
  };

  const customercompanygetapi = async (customeId) => {
    console.log("url==>jwt==>",customeId)
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: true
      },
    });
    const response = await axios.get(`api/customer/companies/${customeId}`);
    console.log("response data==>",response.data)
    const { company } = response.data;
    console.log("ahmed jwt==>",company.length>0 ?company[0] : {})
    dispatch({
      type: 'COMPANYCUSTOMERDATA',
      payload: {
        company:company.length>0 ?company[0] : {},
      },
    });
    // 
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: false
      },
    });
    return response;
  };

  const contractorcompanyinfo = async (contractorcontactid) => {
    const response = await axios.get(`api/superadmin/contractorstore`);
    const { company, contact } = response.data;
    dispatch({
      type: 'COMPANYDATA',
      payload: {
        company,
        contact
      },
    });
  };
  

  const fireextinguisherregister = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("locationphoto", data.locationphoto)
    formData.append("assetid", data.assetid)
    formData.append("assetnumber", data.assetnumber)
    formData.append("assetlocation", data.assetlocation)
    formData.append("fetype", data.fetype)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserial", data.assetserial)
    formData.append("assetrating", data.assetrating)
    formData.append("assetborndate", data.assetborndate)
    formData.append("assetnote", data.assetnote)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    const response = await axios.post('api/customer/addfireextingusher', formData);
    // console.log('response after api hit ->', response.data)
    const { fireextinguisher, error, message } = response.data;
    localStorage.setItem('fireextinguisherID', fireextinguisher.id);
    dispatch({
      type: 'COMPANYFIREEXTINGUISHER',
      payload: {
        fireextinguisher,
        message,
        error
      },
    });
  };

  const updatefireextinguisher = async (data) => {
    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("assetphoto", data.assetphoto)
    formData.append("locationphoto", data.locationphoto)
    formData.append("assetid", data.assetid)
    formData.append("assetnumber", data.assetnumber)
    formData.append("assetlocation", data.assetlocation)
    formData.append("fetype", data.fetype)
    formData.append("assetsize", data.assetsize)
    formData.append("assetmake", data.assetmake)
    formData.append("assetmodel", data.assetmodel)
    formData.append("assetserial", data.assetserial)
    formData.append("assetrating", data.assetrating)
    formData.append("assetborndate", data.assetborndate)
    formData.append("assetnote", data.assetnote)
    formData.append("compressorid", data.compressorid)
    formData.append("contractorid", data.contractorid)
    const response = await axios.post(`api/customer/updatefireextingusher/${data.id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { fireextinguisher, error, message } = response.data;
    localStorage.setItem('fireextinguisherID', fireextinguisher.id);
    dispatch({
      type: 'COMPANYFIREEXTINGUISHER',
      payload: {
        fireextinguisher,
        message,
        error
      },
    });
  };
  const GetAllData = async (locationId)=> {
    console.log(locationId);
    try{
  const response = await axios.get(`/api/customer/companylocationform/${locationId}`);
    console.log( response.data, "getAllDataId");
    const { locationform, status } = response.data;
    console.log(locationform, "locationform from jwt");
    dispatch({
      type: 'LOCATIONFORM',
      payload: {
        locationform,
      },
    });
    }
    catch(e){
console.log(e)
    }
  }
  const GetDocumentData = async (id) => {
    const response = await axios.get(`api/customer/locationuploads/${id}`);
    const { uploads, status } = response.data;
    console.log(response.data, "Uploads from jwt");

    
    dispatch({
      type: 'DOCUMENTFORM',
      payload: {
        uploads,
      },
    });
  }
  
  const getalldefencie = async () => {
    const response = await axios.get(`api/customer/deficiencies`);
    const { deficiencies } = response.data;
    dispatch({
      type: 'DEFICIENCIES',
      payload: {
        deficiencies,
      },
    });
  };
  const companychimesdetail = async ({customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmchime',formData);
    const { chime, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('chimesyID', chime.id);
    dispatch({
      type: 'COMPANYCHIMES',
      payload: {
        chime,
        message,
        error
      },
    });
  }; 

  const companyupdatechimesdetail = async ({id,customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmchime/${id}`,formData);
    const { chime, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('chimesyID', chime.id);
    dispatch({
      type: 'COMPANYCHIMES',
      payload: {
        chime,
        message,
        error
      },
    });
  };
  const companyhornstrobes = async ({customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmhornstrobe',formData);
    const { hornstrobe, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('strobeID', hornstrobe.id);
    dispatch({
      type: 'COMPANYHORNSTROBE',
      payload: {
        hornstrobe,
        message,
        error
      },
    });
  };
  const companyupdatehornstrobes = async (data) => {

    const formData = new FormData();
    formData.append("customerid", data.customerid)
    formData.append("locationid", data.locationid)
    formData.append("firealarmid", data.firealarmid)
    formData.append("photo", data.photo)
    formData.append("make", data.make)
    formData.append("model", data.model)
    formData.append("area_covered", data.systemcovered)
    
    formData.append("date_installed", data.dateinstalled)
    formData.append("notes", data.notes)
    const response = await axios.post(`api/customer/updatefirealarmhornstrobe/${data.id}`,formData);
    const { hornstrobe, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('strobeID', hornstrobe.id);
    dispatch({
      type: 'COMPANYHORNSTROBE',
      payload: {
        hornstrobe,
        message,
        error
      },
    });
  }; 
  const companyhorns = async ({customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post('api/customer/addfirealarmhorn',formData);
    const { horn, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('hornID', horn.id);
    dispatch({
      type: 'COMPANYHORN',
      payload: {
        horn,
        message,
        error
      },
    });
  }; 


  const companyupdatehorns = async ({id,customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
    const response = await axios.post(`api/customer/updatefirealarmhorn/${id}`,formData);
    const { horn, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('hornID', horn.id);
    dispatch({
      type: 'COMPANYHORN',
      payload: {
        horn,
        message,
        error
      },
    });
  };
  const companyphotosmokedetector = async ({customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {


    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
  const response = await axios.post('api/customer/addfirealarmphotosmokedetector',  formData);
  const { photosmokedetector, error, message } = response.data;
  // console.log('response after api hit ->',)
  localStorage.setItem('photosmokedetectorID', photosmokedetector.id);
  dispatch({
    type: 'COMPANYPHOTOSMOKEDETECTOR',
    payload: {
      photosmokedetector,
      message,
      error
    },
  });
};


const companyupdatephotosmokedetector = async ({id,customerid, locationid, firealarmid, photo, make, model,area_covered,date_installed,notes}) => {


    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("locationid", locationid)
    formData.append("firealarmid", firealarmid)
    formData.append("photo", photo)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("area_covered", area_covered)
    formData.append("date_installed", date_installed)
    formData.append("notes", notes)
  const response = await axios.post(`api/customer/updatefirealarmphotosmokedetector/${id}`,  formData);
  const { photosmokedetector, error, message } = response.data;
  // console.log('response after api hit ->',)
  localStorage.setItem('photosmokedetectorID', photosmokedetector.id);
  dispatch({
    type: 'COMPANYPHOTOSMOKEDETECTOR',
    payload: {
      photosmokedetector,
      message,
      error
    },
  });
};
const companystrobesstrobesdetail = async ({customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

  const formData = new FormData();
  formData.append("customerid", customerid)
  formData.append("locationid", locationid)
  formData.append("firealarmid", firealarmid)
  formData.append("photo", photo)
  formData.append("make", make)
  formData.append("model", model)
  formData.append("area_covered", area_covered)
  
  formData.append("date_installed", date_installed)
  formData.append("notes", notes)
  const response = await axios.post('api/customer/addfirealarmstrobe',formData);
  const { strobe, error, message } = response.data;
  // console.log('response after api hit ->',)
  localStorage.setItem('strobeID', strobe.id);
  dispatch({
    type: 'COMPANYSTROBE',
    payload: {
      strobe,
      message,
      error
    },
  });
}; 


const companyupdatestrobesstrobesdetail = async ({id,customerid, locationid, firealarmid,model ,photo,make ,area_covered,date_installed, notes}) => {

  const formData = new FormData();
  formData.append("customerid", customerid)
  formData.append("locationid", locationid)
  formData.append("firealarmid", firealarmid)
  formData.append("photo", photo)
  formData.append("make", make)
  formData.append("model", model)
  formData.append("area_covered", area_covered)
  
  formData.append("date_installed", date_installed)
  formData.append("notes", notes)
  const response = await axios.post(`api/customer/updatefirealarmstrobe/${id}`,formData);
  const { strobe, error, message } = response.data;
  // console.log('response after api hit ->',)
  localStorage.setItem('strobeID', strobe.id);
  dispatch({
    type: 'COMPANYSTROBE',
    payload: {
      strobe,
      message,
      error
    },
  });
};
const companysubpanels = async ({customerid, locationid, firealarmid, photo, make, model,type,date_installed,notes}) => {

  const formData = new FormData();
  formData.append("customerid", customerid)
  formData.append("locationid", locationid)
  formData.append("firealarmid", firealarmid)
  formData.append("photo", photo)
  formData.append("make", make)
  formData.append("model", model)
  formData.append("type", type)
  formData.append("date_installed", date_installed)
  formData.append("notes", notes)
const response = await axios.post('api/customer/addfirealarmsubpanel',  formData);
const { subpanel, error, message } = response.data;
// console.log('response after api hit ->',)
localStorage.setItem('ductdetectorID', subpanel.id);
dispatch({
  type: 'COMPANYSUBPANEL',
  payload: {
    subpanel,
    message,
    error
  },
});
};

const companyupdatesubpanels = async (data) => {

  console.log("data===>", data)
  console.log("dateinstalled ==>", data.date_installed)
  console.log("userId ==>", data.id)

  const formData = new FormData();
  formData.append("customerid", data.customerid)
  formData.append("locationid", data.locationid)
  formData.append("firealarmid", data.firealarmid)
  formData.append("contractorid", data.contractorid)
  formData.append("superadminid", data.id)
  formData.append("photo", data.photo)
  formData.append("make", data.make)
  formData.append("model", data.model)
  formData.append("type", data.type)
  formData.append("date_installed", data.date_installed)
  formData.append("notes", data.notes)
  const response = await axios.post(`api/customer/updatefirealarmsubpanel/${data.id}`, formData);
  const { subpanel, error, message } = response.data;
  // console.log('response after api hit ->',)
  localStorage.setItem('ductdetectorID', subpanel.id);
  dispatch({
    type: 'COMPANYSUBPANEL',
    payload: {
      subpanel,
      message,
      error
    },
  });
};
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        // Global Methods Call
        login,
        register,
        logout,
        ChangePath,
        resetpassword,
        verifypassword,
        // Contractor Methods Call
        contractorcompanyinfo,
        // Customer Methods Call
        companyregister,
        companyupdate,
        customercompany,
        getLoactionandCustomerData,
        customercompanygetapi,
        companycontact,
        companycontactupdate,
        contractorcontact,
        contractorcontactupdate,
        companylocation,
        companylocationupdate,
        companyservice,
        companyserviceupdate,
        companyjob,
        companyjobupdate,
        companyelevator,
        companyelevatorupdate,
        // Turabi
        delugeisolationadd,
        delugeisolationupdate,
        deleugehydraulicadd,
        deleugehydraulicupdate,
        companybelldetector,
        delugeheadadd,
        delugeheadupdate,
        delugeguageadd,
        delugeguageupdate,
        delugedrainadd,
        delugedrainupdate,
        companykeylogs,
        companyfiredep,
        updatecompanyfiredep,
        firealarmregister,
        updatefirealarmregister,
        drysprinklerregister,
        updatedrysprinklerregister,
        wetsprinklerregister,
        updatewetsprinklerregister,
        firepumpregister,
        updatefirepumpregister,
        preactionregister,
        updatepreaction,
        delugeregister,
        updatedelugeregister,
        standpiperegister,
        updatestandpiperegister,
        firehydrantregister,
        updatefirehydrantregister,
        kitchenhoodregister,
        updatekitchenhoodregister,
        cleanagentregister,
        updatecleanagentregister,
        drychemicalregister,
        updatedrychemicalregister,
        fireescaperegister,
        updatefireescaperegister,
        fireextinguisherregister,
        updatefireextinguisher,
        backflowregister,
        updatebackflowregister,
        otherlocationregister,
        updateotherlocationregister,
        companybatterydetail,
        companyupdatebatterydetail,
        companyductdetector,
        companyupdateductdetector,
        companyheatdetector,
        companyupdateheatdetector,
        companyflowswitch,
        companyupdateflowswitch,
        companysupervisory,
        companyupdatesupervisory,
        companywetantifreeze,
        companyupdatewetantifreeze,
        companywetguage,
        companyupdatewetguage,
        companywetnameplate,
        companyupdatewetnameplate,
        companywetisolation,
        companyupdatewetisolation,
        companywetdrain,
        companyupdatewetdrain,
        companywethead,
        companyupdatewethead,
        companydryantifreeze,
        companydryguage,
        companyupdatedryguage,
        companydrynameplate,
        companyupdatedrynameplate,
        companydryisolation,
        companyupdatedryisolation,
        companydrydrain,
        companyupdatedrydrain,
        companydryhead,
        companyupdatedryhead,
        companypreantifreeze,
        companypreguage,
        companyupdatepreguage,
        companyprenameplate,
        companyupdateprenameplate,
        companypreisolation,
        companyupdatepreisolation,
        companypredrain,
        companyupdatepredrain,
        companyprehead,
        companyupdateprehead,
        companyspguage,
        companyupdatespguage,
        companyspisolation,
        companyupdatespisolation,
        companyfpguage,
        companyupdatefpguage,
        companyfpisolation,
        companyupdatefpisolation,
        companykhcylinderdetail,
        companyupdatekhcylinderdetail,
        companycacylinderdetail,
        companyupdatecacylinderdetail,
        companycylinderdetail,
        companyupdatecylinderdetail,
        companyfhdetail,
        companyupdatefhdetail,
        companyfedetail,
        companyupdatefedetail,
        companybfisolation,
        companyupdatebfisolation,
        GetDocumentData,
        getalldefencie,
        updatecompanykeylogs,
        subadminregister,
        customercompanypostapi,
        GetAllData,
        companyupdatepullstations,
        companysmokedetector, 
        companyupdatesmokedetector,
        companyairmaintainance,
        updatecompanyairmaintainance,
        companyupdatebelldetector,
        companychimesdetail,
        companyupdatechimesdetail,
        companyhornstrobes,
        companyhorns,
        companyupdatehorns,
        companyphotosmokedetector,
        companyupdatephotosmokedetector,
        companypullstations,
        companystrobesstrobesdetail,
        companyupdatestrobesstrobesdetail,
        companysubpanels,
        companyupdatesubpanels,
        companyupdatehornstrobes,


      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

