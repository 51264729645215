// @mui
import MUIDataTable from "mui-datatables";
import {
  Container,
  Box,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from "../../../components/hook-form";
// import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, Input } from '@mui/material';
import axios from "../../../utils/axios";

// ----------------------------------------------------------------------

export default function TodaySchedule() {
  useEffect(() => {
    GetToday();
  }, []);
  const [data, setData] = useState();
  const GetToday = async () => {
    const response = await axios.get(`api/superadmin/todaysjobs`);
    const { todayjobs } = response.data;
    setData(todayjobs);
  };

  const columns = [
    {
      name: "assetid",
      label: "Asset ",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "customer",
      label: "Customer Company",
      options: {
        customBodyRender: (value, row) => {
          return value?.company?.companyname;
        },
      },
    },
    {
      name: "contractorcompanyname",
      label: "Contractor Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "technicianname",
      label: "Technician",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jobscheduleddate",
      label: "Job Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jobtype",
      label: "Job Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jobstatus",
      label: "Job Status",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
  };

  const handleChange = () => {
    alert("this is running");
  };

  return (
    // <Card
    //   sx={{
    //     '& .MuiTimelineItem-missingOppositeContent:before': {
    //       display: 'none',
    //     },
    //   }}
    // >
    //   <CardHeader title="Today Schedule Timeline" />
    //   <CardContent>
    //     {/* <MUIDataTable
    //       title={"Location List"}
    //       data={data}
    //       columns={columns}
    //       options={{
    //         selectableRows: false // <===== will turn off checkboxes in rows
    //       }}
    //     /> */}
    //     <Timeline>
    //       {_analyticOrderTimeline.map((item, index) => (
    //         <OrderItem key={item.id} item={item} isLast={index === _analyticOrderTimeline.length - 1} />
    //       ))}
    //     </Timeline>
    //   </CardContent>
    // </Card>

    <Card>
      <MUIDataTable
        title={"ToDays Jobs"}
        data={data}
        columns={columns}
        options={{
          selectableRows: false, // <===== will turn off checkboxes in rows
        }}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

// OrderItem.propTypes = {
//   isLast: PropTypes.bool,
//   item: PropTypes.shape({
//     time: PropTypes.instanceOf(Date),
//     title: PropTypes.string,
//     type: PropTypes.string,
//   }),
// };

// function OrderItem({ item, isLast }) {
//   const { type, title, time } = item;
//   return (
//     <TimelineItem>
//       <TimelineSeparator>
//         <TimelineDot
//           color={
//             (type === 'order1' && 'primary') ||
//             (type === 'order2' && 'success') ||
//             (type === 'order3' && 'info') ||
//             (type === 'order4' && 'warning') ||
//             'error'
//           }
//         />
//         {isLast ? null : <TimelineConnector />}
//       </TimelineSeparator>
//       <TimelineContent>
//         <Typography variant="subtitle2">{title}</Typography>
//         <Typography variant="caption" sx={{ color: 'text.secondary' }}>
//           {fDateTime(time)}
//         </Typography>
//       </TimelineContent>
//     </TimelineItem>
//   );
// }
